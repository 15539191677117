<template>
  <div
    class="ui-radio"
    :class="{
      'ui-radio--disabled': disabled,
    }"
  >
    <input
      type="radio"
      :id="id"
      :disabled="disabled"
      class="ui-radio__input"
      :checked="isChecked"
      @change="onChange(value)"
    />
    <label :for="id" class="ui-radio__label">
      <span class="ui-radio__label__text" v-if="reverse">
        <slot></slot>
      </span>
      <span class="ui-radio__label__circle" :class="{ 'ui-radio__label__circle--reverse': reverse }">
        <span class="ui-radio__label__circle__dot"></span>
      </span>
      <span class="ui-radio__label__text" v-if="!reverse">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'radio',
  model: {
    prop: 'inputValue',
    event: 'change',
  },
  props: {
    inputValue: {
      type: [Array, Boolean, Number, String, Object],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    value: [String, Object, Boolean, Number],
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.inputValue === this.value
    },
  },
  methods: {
    onChange(value) {
      /**
       * Emitted when the radio is selected.
       * @event change
       * @type {Event}
       */
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-radio {
  @include input;

  display: inline-flex;

  &__label {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;

    .ui-radio__input:disabled + & {
      cursor: default;
    }

    &__circle {
      position: relative;
      transform: scale(1);
      transition: all 0.16s ease;
      margin-right: 12px;
      border: 2px solid $radio-color-border;
      border-radius: 50%;
      background-color: $radio-color-bg;
      width: $radio-size;
      height: $radio-size;

      &--reverse {
        margin-right: 0;
        margin-left: 12px;
      }

      .ui-radio__input:disabled + label & {
        border-color: $radio-color-border;
      }

      .ui-radio__input:checked:not(:disabled) + label &,
      .ui-radio__input:hover:not(:disabled) + label &,
      .ui-radio__input:focus:not(:disabled) + label & {
        border-color: $radio-color-border-hover;
      }

      &__dot {
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(0);
        transition: transform $transition-duration-default cubic-bezier(0.4, 0, 0.6, 1);
        border-radius: 50%;
        background-color: $radio-color-text-hover;
        width: 100%;
        height: 100%;

        .ui-radio__input:checked + label & {
          transform: scale(0.6);
        }

        .ui-radio__input:checked:disabled + label & {
          background-color: $radio-color-border;
        }
      }
    }

    &__text {
      color: $radio-color-text;

      .ui-radio__input:hover:not(:disabled) + label &,
      .ui-radio__input:focus:not(:disabled) + label & {
        color: $radio-color-text-hover;
      }
    }
  }

  &__input {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }
}
</style>
