<template>
  <modal
    name="modal-feature"
    :title="title"
    :has-apply="true"
    width="400"
    :is-updating="isUpdating"
    @save="save"
    @closed="closed"
  >
    <template v-slot:container>
      <feature-list-selection
        :features="getFeaturesWithDetails()"
        :activeList="activeList"
        :exceptions="exceptions"
        :parameters="parameters"
        @inputDataChanged="inputDataChanged"
      />
    </template>
  </modal>
</template>

<script>
import FeatureListSelection from '@/components/FeatureList/FeatureListSelection.vue'

export default {
  name: 'ModalFeature',
  components: {
    FeatureListSelection,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    features: {
      type: Array,
      required: true,
    },
    featuresDetails: {
      type: Array,
      required: false,
      default: () => [],
    },
    activeList: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },
    exceptions: {
      type: Object,
      required: false,
      default: () => {},
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        mode: '',
      }),
    },
  },
  data() {
    return {
      modelActiveList: null,
      modelExceptions: null,
    }
  },
  methods: {
    getFeaturesWithDetails() {
      this.featuresDetails.forEach(featureDetails => {
        this.features
          .filter(feature => featureDetails.key === feature.tag)
          .forEach(featureItem => {
            featureItem.items = featureDetails.items
            featureItem.title = featureDetails.title
          })
      })

      return this.features
    },
    inputDataChanged({ activeList, exceptions }) {
      this.modelActiveList = activeList
      this.modelExceptions = exceptions
    },
    save() {
      const objKey = {
        featureList: this.modelActiveList,
      }

      if (this.parameters.mode === 'frontoffice') {
        objKey.featureListException = this.modelExceptions
      }

      this.$emit('save', {
        objKey,
      })
    },
    closed() {
      this.$emit('closed')
    },
  },
}
</script>
