<template>
  <modal
    :title="title"
    name="modal-hours"
    :has-apply="true"
    :has-delete="canBeDeleted"
    :is-updating="isUpdating"
    :width="900"
    :delete-label="$t('modal.hours.delete.label')"
    :delete-tooltip="$t('modal.hours.delete.tooltip')"
    @save="save"
    @remove="remove"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-input
        class="modal-hours__input"
        v-model.trim="modelData.label"
        :label="$t('modal.hours.label.name')"
        id="modal-hour-name"
        v-if="parameters.type !== 'gmb'"
      />
      <div class="modal-hours__visibility" v-if="parameters.type !== 'gmb'">
        <div class="modal-hours__visibility__block">
          <ui-date-picker
            class="modal-hours__visibility__block__input"
            v-model="dateFrom"
            :locale="$i18n.locale"
            :max-date="dateTo"
            id="modal-media-from"
            :label="$t('modal.hours.label.dateFrom')"
          />
          <ui-button
            class="modal-hours__visibility__block__remove"
            v-if="dateFrom"
            button="secondary"
            icon="close"
            :icon-only="true"
            :label="$t('modal.hours.button.removeDateFrom')"
            v-tooltip="{
              placement: 'top',
              trigger: 'hover',
              content: $t('modal.hours.button.removeDateFrom'),
              offset: 3,
            }"
            @click="clearDate('dateFrom')"
          />
        </div>
        <div class="modal-hours__visibility__block">
          <ui-date-picker
            class="modal-hours__visibility__block__input"
            v-model="dateTo"
            :locale="$i18n.locale"
            :min-date="dateFrom"
            id="modal-media-to"
            :label="$t('modal.hours.label.dateTo')"
          />
          <ui-button
            class="modal-hours__visibility__block__remove"
            v-if="dateTo"
            button="secondary"
            icon="close"
            :icon-only="true"
            :label="$t('modal.hours.button.removeDateTo')"
            v-tooltip="{
              container: '.modal__wrapper__box',
              placement: 'top',
              trigger: 'hover',
              content: $t('modal.hours.button.removeDateTo'),
              offset: 3,
            }"
            @click="clearDate('dateTo')"
          />
        </div>
      </div>
      <v-tabs v-model="tab" class="modal-hours__tabs">
        <v-tab v-for="tab in tabs" :key="tab"> {{ $t(`modal.hours.tabs.${tab}`) }} </v-tab>
      </v-tabs>
      <v-tabs-items class="modal-hours__items" v-model="tab">
        <v-tab-item v-for="tab in tabs" :key="`item ${tab}`" class="modal-hours__items__day">
          <div class="modal-hours__tabs__items__day__grid">
            <modal-extend-hours-day
              v-for="(day, idx) in daysArray"
              :key="`day-${day.key}-${idx}`"
              :hours-format="hoursFormat"
              :current-client="currentClient"
              :day-key="day.key"
              :day-idx="idx"
              :day="day"
              :days-array="daysArray"
              :duplicating-day="duplicatingDay"
              :duplicate-array="duplicateArray"
              :validations="$v"
              :his-custom-hours="tab === 'appointment'"
              @toggleSlots="toggleSlots"
              @stopDuplicating="stopDuplicating"
              @duplicate="duplicate"
              @endDuplicating="endDuplicating"
              @addSlot="addSlot"
              @addCustomSlot="addCustomSlot"
              @deleteSlot="deleteSlot"
              @deleteCustomSlot="deleteCustomSlot"
              @setDuplicateArray="setDuplicateArray"
              @updateSlotHours="updateSlotHours"
            ></modal-extend-hours-day>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </modal>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiInput from '@/components/UI/Input.vue'
import UiDatePicker from '@/components/UI/DatePicker.vue'
import ModalExtendHoursDay from '@/components/Modal/ExtendHoursDay.vue'
import { requiredIf } from 'vuelidate/lib/validators'
import { formatedDate, orderArrayOfWeekday } from '@/utils/date.util'
import { convert24hto12h } from '@/utils/hours.util'

export default {
  name: 'ModalHours',
  components: {
    ModalExtendHoursDay,
    UiButton,
    UiInput,
    UiDatePicker,
  },
  props: {
    haveAppointment: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    currentClient: {
      type: Object,
      required: false,
      default: () => {},
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    hoursFormat: {
      type: Boolean,
      required: false,
      default: false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        type: '',
      }),
    },
  },
  data() {
    return {
      modelData: null,
      daysArray: [
        {
          key: 'monday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'tuesday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'wednesday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'thursday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'friday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'saturday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'sunday',
          open: false,
          h24: false,
          slots: [],
        },
      ],
      duplicatingDay: null,
      duplicateArray: [],
      canBeDeleted: false,
      tab: 0,
      tabs: this.haveAppointment ? ['schedule', 'appointment'] : ['schedule'],
    }
  },
  watch: {
    tab() {
      this.duplicatingDay = null
      this.convertData(!this.tab)
      this.stopDuplicating()
      this.generateDays(this.tab)
    },
  },
  mounted() {
    this.modelData = Object.assign({}, this.data, {
      ...this.data,
      dateFrom: this.data?.dateFrom || '',
      dateTo: this.data?.dateTo || '',
    })
    if (this.modelData && this.modelData.id) {
      this.canBeDeleted = true
      this.generateDays(this.tab)
    }
  },
  computed: {
    dateFrom: {
      get: function () {
        if (this.modelData.dateFrom) {
          return new Date(this.modelData.dateFrom)
        }
        return ''
      },
      set(date) {
        this.modelData.dateFrom = formatedDate(date, 'YYYY-MM-DD')
      },
    },
    dateTo: {
      get: function () {
        if (this.modelData.dateTo) {
          return new Date(this.modelData.dateTo)
        }
        return ''
      },
      set(date) {
        this.modelData.dateTo = formatedDate(date, 'YYYY-MM-DD')
      },
    },
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.convertData(this.tab)
        if (this.modelData.id) {
          this.$emit('save', {
            objKey: this.modelData,
            status: 'update',
          })
        } else {
          this.$emit('save', {
            objKey: this.modelData,
            status: 'create',
          })
        }
        this.$v.$reset()
      }
    },
    remove() {
      this.$emit('save', {
        objKey: this.modelData,
        status: 'delete',
      })
    },
    closed() {
      this.$emit('closed')
    },
    addSlot(data) {
      data.slots.push({
        opening: '',
        closing: '',
      })
    },
    addCustomSlot(data) {
      data.slots = [
        {
          opening: '',
          closing: '',
        },
        {
          opening: '',
          closing: '',
        },
      ]
    },
    deleteCustomSlot(data) {
      data.slots = []
    },
    deleteSlot(payload) {
      payload.day.slots.splice(payload.index, 1)
    },
    updateSlotHours(payload) {
      this.daysArray = this.daysArray.map((day, index) => {
        if (index === payload.dayIdx) {
          const slots = day.slots
          slots[payload.idxSlot][payload.isOpening ? 'opening' : 'closing'] = payload.value
          return {
            ...day,
            slots,
          }
        } else {
          return day
        }
      })
      this.daysArray[payload.dayIdx].slots[payload.idxSlot][payload.isOpening ? 'opening' : 'closing'] = payload.value
    },
    setDuplicateArray(dayArray) {
      this.duplicateArray = dayArray
    },
    duplicate(data) {
      this.duplicatingDay = data
    },
    endDuplicating() {
      this.daysArray = this.daysArray.map(day => {
        if (this.duplicateArray.includes(day.key)) {
          return {
            key: day.key,
            open: this.duplicatingDay.open,
            h24: this.duplicatingDay.h24,
            slots: this.duplicatingDay.slots.map(slot => this.cloneSlot(slot)),
          }
        } else {
          return day
        }
      })
      this.duplicatingDay = null
    },
    stopDuplicating() {
      this.duplicateArray = []
    },
    cloneSlot(slot) {
      return Object.assign(
        {},
        {
          opening: slot.opening,
          closing: slot.closing,
        }
      )
    },
    toggleSlots(item) {
      this.daysArray = this.daysArray.map(day => {
        if (day.key === item.key) {
          return {
            ...day,
            slots:
              day.open && !day.h24
                ? [
                    {
                      opening: '',
                      closing: '',
                    },
                  ]
                : [],
          }
        } else {
          return day
        }
      })
    },
    generateDays(isAppointment) {
      const openingHoursArray = []
      const openingHoursObject = isAppointment
        ? Object.entries(this.modelData.hoursBookingOnly)
        : Object.entries(this.modelData.hours)

      for (const [day, time] of openingHoursObject) {
        let slots = time.map(t => {
          if (isAppointment) {
            return {
              opening: t,
              closing: '',
            }
          } else {
            return {
              opening: { name: this.convertTime(t.split('-')[0]), id: t.split('-')[0] },
              closing: { name: this.convertTime(t.split('-')[1]), id: t.split('-')[1] },
            }
          }
        })
        const h24 =
          slots.length === 1 &&
          (slots[0].opening.id === '0:00' || slots[0].opening.id === '00:00') &&
          (slots[0].closing.id === '0:00' || slots[0].closing.id === '00:00')
        if (h24) {
          slots = []
        }
        openingHoursArray.push({
          key: day,
          open: isAppointment ? true : slots.length > 0 || h24,
          h24,
          slots,
        })
      }
      this.daysArray = orderArrayOfWeekday(openingHoursArray, 'key')
    },
    convertData(isAppointment) {
      const hours = {}
      this.daysArray.map(day => {
        if (isAppointment) {
          hours[day.key] = day.slots.map(slot => `${slot.opening}`)
        } else if (!day.open) {
          hours[day.key] = []
        } else if (day.h24) {
          hours[day.key] = ['0:00-0:00']
        } else {
          hours[day.key] = day.slots.map(slot => `${slot.opening.id}-${slot.closing.id}`)
        }
      })

      if (isAppointment) {
        this.modelData.hoursBookingOnly = hours
      } else {
        this.modelData.hours = hours
      }
    },
    convertTime(time) {
      const amPm = !!(this.currentClient.ampmFormat * 1)
      if (amPm) {
        return convert24hto12h(time)
      }
      return time
    },
    clearDate(date) {
      this.modelData[date] = ''
    },
  },
  validations() {
    return {
      daysArray: {
        $each: {
          slots: {
            $each: {
              opening: {
                required: requiredIf(() => {
                  if (this.tab === 1) {
                    return false
                  }
                  return true
                }),
              },
              closing: {
                required: requiredIf(() => {
                  if (this.tab === 1) {
                    return false
                  }
                  return true
                }),
              },
            },
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-hours {
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }
  }

  &__visibility {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      display: flex;
      justify-content: space-between;
      margin-bottom: $gutter-tablet;
    }

    &__block {
      position: relative;
      margin-bottom: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-bottom: 0;
        width: calc(50% - #{$gutter-tablet} / 2);
      }

      &__remove {
        position: absolute;
        right: 1px;
        bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__tabs {
    border-top-left-radius: $radius-default;
    border-top-right-radius: $radius-default;
  }

  &__items {
    overflow-y: scroll;
    &__day {
      padding: $gutter-mobile;
      &__grid {
        margin: (-$gutter-mobile) (-$gutter-mobile / 2);

        @media (min-width: $screen-sm) {
          margin: (-$gutter-mobile) (-$gutter-tablet / 2);
        }
      }
    }
  }
}
</style>
