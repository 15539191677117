<template>
  <modal
    :title="$t('modal.clipper.title')"
    :name="name"
    :has-apply="true"
    :apply-label="$t('common.button.finish')"
    apply-variant="data"
    @save="save"
    :hide-overlay="true"
    height="100%"
    class="modal-clipper"
  >
    <template v-slot:container>
      <div class="modal-clipper__container">
        <div class="modal-clipper__container__preview">
          <clipper-basic
            class="modal-clipper__container__preview__clipper"
            ref="imgClipper"
            :src="image"
            :ratio="ratio"
            :rotate="rotate"
            :scale="scale"
            :init-width="100"
            :init-height="100"
            :bg-color="backgroundColor"
          />
        </div>

        <div class="modal-clipper__container__filters">
          <section class="modal-clipper__container__filters__section">
            <div class="modal-clipper__container__filters__section__title">
              {{ $t('modal.clipper.background.title') }}
            </div>
            <div class="modal-clipper__container__filters__section__block">
              <ui-radio value="transparent" id="bg-transparent" v-model="backgroundColor">
                {{ $t('modal.clipper.background.none') }}
              </ui-radio>
              <ui-radio value="#fff" id="bg-white" v-model="backgroundColor">
                {{ $t('modal.clipper.background.white') }}
              </ui-radio>
              <ui-radio value="#000" id="bg-black" v-model="backgroundColor">
                {{ $t('modal.clipper.background.black') }}
              </ui-radio>
            </div>
          </section>
          <section class="modal-clipper__container__filters__section">
            <ui-button
              class="modal-clipper__container__filters__section__zoom-out"
              :label="$t('modal.clipper.button.zoomOut')"
              button="secondary"
              icon="zoom_out"
              :icon-only="true"
              :disabled="scale === 1"
              v-tooltip="{
                placement: 'top',
                trigger: 'hover',
                content: $t('modal.clipper.button.zoomOut'),
                offset: 3,
              }"
              @click.prevent="zoomOut"
            />

            <vue-slider
              class="modal-clipper__container__filters__section__slider"
              v-model="scale"
              tooltip="none"
              :min="1"
              :max="5"
              :clickable="false"
              :dotSize="24"
              :interval="0.1"
              :enable-cross="false"
              :rail-style="{
                backgroundColor: '#ddd',
                height: '6px',
              }"
              :dot-style="{
                backgroundColor: '#449afd',
                border: '2px solid #fff',
                boxShadow: 'none',
              }"
              :process-style="{ backgroundColor: '#449afd' }"
              :tooltip-style="{
                backgroundColor: '#449afd',
                borderColor: '#449afd',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                padding: '4px 8px',
              }"
            />

            <ui-button
              class="modal-clipper__container__filters__section__zoom-in"
              :label="$t('modal.clipper.button.zoomIn')"
              button="secondary"
              icon="zoom_in"
              :icon-only="true"
              :disabled="scale === 5"
              v-tooltip="{
                placement: 'top',
                trigger: 'hover',
                content: $t('modal.clipper.button.zoomIn'),
                offset: 3,
              }"
              @click.prevent="zoomIn"
            />
          </section>
          <section class="modal-clipper__container__filters__section">
            <ui-button
              class="modal-clipper__container__filters__section__rotate-left"
              :label="$t('modal.clipper.button.rotateLeft')"
              button="secondary"
              icon="rotate_left"
              :icon-only="true"
              :disabled="rotate === -180"
              v-tooltip="{
                placement: 'top',
                trigger: 'hover',
                content: $t('modal.clipper.button.rotateLeft'),
                offset: 3,
              }"
              @click.prevent="rotateLeft"
            />

            <vue-slider
              class="modal-clipper__container__filters__section__slider"
              v-model="rotate"
              tooltip="none"
              :min="-180"
              :max="180"
              :clickable="false"
              :dotSize="24"
              :interval="1"
              :enable-cross="false"
              :rail-style="{
                backgroundColor: '#ddd',
                height: '6px',
              }"
              :dot-style="{
                backgroundColor: '#449afd',
                border: '2px solid #fff',
                boxShadow: 'none',
              }"
              :process="false"
              :process-style="{ backgroundColor: '#449afd' }"
              :tooltip-style="{
                backgroundColor: '#449afd',
                borderColor: '#449afd',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                padding: '4px 8px',
              }"
            />

            <ui-button
              class="modal-clipper__container__filters__section__rotate-right"
              :label="$t('modal.clipper.button.rotateRight')"
              button="secondary"
              icon="rotate_right"
              :icon-only="true"
              :disabled="rotate === 180"
              v-tooltip="{
                placement: 'top',
                trigger: 'hover',
                content: $t('modal.clipper.button.rotateRight'),
                offset: 3,
              }"
              @click.prevent="rotateRight"
            />
          </section>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiRadio from '@/components/UI/Radio.vue'
import VueSlider from 'vue-slider-component'
import { clipperBasic } from 'vuejs-clipper'

export default {
  name: 'ModalClipper',
  components: {
    UiButton,
    UiRadio,
    clipperBasic,
    VueSlider,
  },
  props: {
    image: {
      type: String,
      required: false,
      default: '',
    },
    ratio: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: 'modal-clipper',
    },
  },
  data() {
    return {
      title: '',
      rotate: 0,
      scale: 1,
      backgroundColor: 'transparent',
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      this.backgroundColor = 'transparent'
      this.$nextTick(() => {
        const canvas = this.$refs.imgClipper.clip()
        this.$emit('clip-picture', canvas)
      })
    },
    zoomOut() {
      if (this.scale - 0.5 < 1) {
        this.scale = 1
      } else {
        this.scale = this.scale - 0.5
      }
    },
    zoomIn() {
      if (this.scale + 0.5 > 5) {
        this.scale = 5
      } else {
        this.scale = this.scale + 0.5
      }
    },
    rotateLeft() {
      if (this.rotate - 10 < -180) {
        this.rotate = -180
      } else {
        this.rotate = this.rotate - 10
      }
    },
    rotateRight() {
      if (this.rotate + 10 > 180) {
        this.rotate = 180
      } else {
        this.rotate = this.rotate + 10
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-clipper {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__preview {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin-bottom: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-bottom: $gutter-tablet;
      }

      &__clipper {
        width: 100%;
        max-width: 450px;
      }
    }

    &__filters {
      &__section {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: $gutter-mobile;
        width: 100%;

        @media (min-width: $screen-sm) {
          margin-bottom: $gutter-tablet;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &__title {
          margin-bottom: 6px;
          width: 100%;
          font-weight: bold;
        }

        &__block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        &__zoom-out,
        &__rotate-left {
          margin-right: $gutter-mobile;
        }

        &__zoom-in,
        &__rotate-right {
          margin-left: $gutter-mobile;
        }

        &__slider {
          flex: 1;
        }
      }
    }
  }
}
</style>
