import { notification } from '@/utils/notification.util'

export const notif = {
  data() {
    return {
      isUpdating: false,
    }
  },
  methods: {
    notificationSuccess(view, name, type = 'success') {
      this.isUpdating = false
      notification({
        text: this.$t(`common.notification.${type}.${view}`, {
          name,
        }),
        type: 'success',
      })
    },
    notificationError(type = 'error') {
      this.isUpdating = false
      notification({
        text: this.$t(`common.notification.${type}`),
        type: 'error',
      })
    },
  },
}
