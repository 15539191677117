export const rewriteUrl = url => {
  let formatUrl = url
  const domainUrl = url.includes('//') ? url.split('//')[1] : url
  if (url.includes('http://')) {
    formatUrl = `https://${domainUrl}`
  }
  if (domainUrl && domainUrl.slice(-1) !== '/' && !domainUrl.includes('/')) {
    formatUrl = formatUrl.includes('?') ? formatUrl.replace('?', '/?') : formatUrl + '/'
  }
  return formatUrl
}

export const rewriteSlug = slug => {
  const regexSlug = /:|\/|\?|#|\[|]|@|!|$|&|'|\(|\)|\*|\+|,|;|=|"/g
  let formatSlug = slug.replace("'", '-').replace(regexSlug, '').replace(/-+/g, '-')
  return formatSlug
}
