<template>
  <modal
    name="modal-external-links"
    :title="title"
    :has-apply="true"
    :is-updating="isUpdating"
    :has-delete="!isNew"
    :delete-label="$t('modal.externalUrls.delete.label')"
    :delete-tooltip="$t('modal.externalUrls.delete.tooltip')"
    @save="save"
    @remove="remove"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-input
        class="required-asterisk modal-external-links__input"
        v-model="modelData.title"
        :label="$t('modal.externalLinks.label.title')"
        id="modal-link-title"
        :error="$v.modelData.title.$error"
      />
      <ui-input
        class="required-asterisk modal-external-links__input"
        v-model="modelData.label"
        :label="$t('modal.externalLinks.label.label')"
        id="modal-link-label"
        :error="$v.modelData.label.$error"
      />
      <ui-input
        class="required-asterisk modal-external-links__input"
        v-model="modelData.url"
        :label="$t('modal.externalLinks.label.url')"
        id="modal-module-url"
        :error="$v.modelData.url.$error"
      />
      <ui-switch
        ref="externalLinksExternal"
        class="location-locator__toggle"
        id="external-link-is-external"
        :input-value="modelData.external"
        :value="modelData.external"
        :reverse="true"
        align="right"
        @input="() => (modelData.external = !modelData.external)"
      >
        {{ $t('modal.externalLinks.label.external') }}
      </ui-switch>
    </template>
  </modal>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators'
import UiInput from '@/components/UI/Input.vue'
import UiSwitch from '@/components/UI/Switch.vue'

export default {
  name: 'ModalExternalLinks',
  components: {
    UiInput,
    UiSwitch,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({
        title: '',
        label: '',
        url: '',
        external: true,
      }),
    },
    locationExternalLinks: {
      type: Array,
      default: () => [],
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: {},
    }
  },
  mounted() {
    this.modelData = { ...this.data }
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      if (this.isNew) {
        this.$emit('save', {
          objKey: {
            externalLinks: [...this.locationExternalLinks, this.modelData],
          },
        })
      } else {
        const currentExternalLink = this.locationExternalLinks.find(link => this.modelData.title === link.title)
        const otherLinks = this.locationExternalLinks.filter(link => link.title !== this.modelData.title)
        this.$emit('save', {
          objKey: {
            externalLinks: [...otherLinks, { ...currentExternalLink, ...this.modelData }],
          },
        })
      }
      this.$v.$reset()
    },
    remove() {
      const externalLinks = this.locationExternalLinks.filter(link => this.modelData.title !== link.title)
      this.$emit('save', { objKey: { externalLinks } })
    },
    closed() {
      this.$emit('closed')
    },
  },
  computed: {
    isNew() {
      return !this.locationExternalLinks.some(link => this.modelData.title === link.title)
    },
  },
  validations() {
    return {
      modelData: {
        title: {
          required,
        },
        label: {
          required,
        },
        url: {
          required,
          url,
        },
        external: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-external-links {
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
