export const orderEvent = (events, targetId, currentItemId) => {
  const targetIndex = events.findIndex(event => event.id == targetId)
  const currentItemIndex = events.findIndex(event => event.id == currentItemId)
  const currentItem = events[currentItemIndex]
  events.splice(currentItemIndex, 1)
  events.splice(targetIndex, 0, currentItem)
  return events
}

export const orderEventGroup = (eventGroup, targetId, currentItemId, currentGroupId) => {
  const events = eventGroup.filter(event => event.id == currentGroupId).map(event => event.events)[0]
  orderEvent(events, targetId, currentItemId)
  return eventGroup
}
