var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-grid',{attrs:{"with-margin":true}},[_c('screen-card',{attrs:{"no-padding":true,"overflow-hidden":true},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{ref:"fixedElement",staticClass:"screen-tabs",style:(_vm.style)},[_c('div',{staticClass:"screen-tabs__wrapper",class:{
            'screen-tabs__wrapper--fixed': _vm.elementFixed,
          }},[_c('div',{staticClass:"screen-tabs__wrapper__container",class:{
              'screen-tabs__wrapper__container--fixed-container': _vm.elementFixed,
            }},_vm._l((_vm.filteredTabs),function(tab,idx){return _c('div',{key:("tab-" + idx),staticClass:"screen-tabs__wrapper__container__tab",class:{
                'screen-tabs__wrapper__container__tab--selected': _vm.currentTab === tab.key && !_vm.isLoading,
                'screen-tabs__wrapper__container__tab--disabled': tab.disabled && !_vm.isLoading,
              },on:{"click":function($event){!tab.disabled && !_vm.isLoading ? _vm.setCurrentTab(tab.key) : false}}},[(_vm.isLoading)?_c('skeleton-line',{attrs:{"height":"18px","width":"120px"}}):[_vm._v(" "+_vm._s(tab.label)+" "),(_vm.tabHasStatusDisplay(tab))?_c('div',{staticClass:"screen-tabs__wrapper__container__tab__dot",class:tab.status.active
                      ? 'screen-tabs__wrapper__container__tab__dot--active'
                      : 'screen-tabs__wrapper__container__tab__dot--inactive'}):_vm._e(),(_vm.tabHasBadgeDisplay(tab))?_c('v-badge',{staticClass:"tw-ml-2 tw-mt-1",attrs:{"color":"#449afd","content":tab.badge.content}}):_vm._e()]],2)}),0)])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }