<template>
  <modal :title="title" name="modal-seo" :has-apply="true" :is-updating="isUpdating" @save="save" @closed="closed">
    <template v-slot:container>
      <ui-dropdown
        v-if="parameters.options"
        id="modal-seo"
        class="modal-seo__dropdown"
        v-model="modelType"
        :options="parameters.options"
        :placeholder="label.placeholder"
        :dropdown-label="label.dropdown"
        :show-label="true"
        :max-height="120"
      />
      <ui-textarea
        v-model.trim="modelData[modelType]"
        :label="hasMaxLength(objKey) ? `${label.description} ${parameters.helpers.max}` : label.description"
        id="modal-field"
        :maxCharactersLength="hasMaxLength(objKey) ? 255 : null"
      />
      <div v-if="parameters.helpers" text type="info">
        <div v-if="objKey === 'locationMetaDescription' || objKey === 'locationMetaTitle'" class="modal-seo__helpers">
          <div>
            <span class="tw-font-bold">{{ parameters.helpers.title }}</span>
            <div class="tw-mt-2">
              <code>{{ parameters.helpers.location }}</code>
              <span>
                {{
                  `: ${parameters.seoCharCount.shortestName.length} ${parameters.helpers.to} ${parameters.seoCharCount.longestName.length} ${parameters.helpers.char}`
                }}</span
              >
            </div>
            <ul class="modal-seo__helpers__list">
              <li>
                <v-icon x-small>{{ icons.mdiArrowRightThin }}</v-icon>
                {{ `${parameters.helpers.shortest}"${parameters.seoCharCount.shortestName.text}"` }}
              </li>
              <li>
                <v-icon x-small>{{ icons.mdiArrowRightThin }}</v-icon>
                {{ `${parameters.helpers.longest}"${parameters.seoCharCount.longestName.text}"` }}
              </li>
            </ul>
            <div class="tw-mt-2">
              <code>{{ parameters.helpers.city }}</code>
              <span>
                {{
                  `: ${parameters.seoCharCount.shortestCity.length} ${parameters.helpers.to} ${parameters.seoCharCount.longestCity.length} ${parameters.helpers.char}`
                }}
              </span>
            </div>

            <ul class="modal-seo__helpers__list">
              <li>
                <v-icon x-small>{{ icons.mdiArrowRightThin }}</v-icon>
                {{ `${parameters.helpers.shortest}"${parameters.seoCharCount.shortestCity.text}"` }}
              </li>
              <li>
                <v-icon x-small>{{ icons.mdiArrowRightThin }}</v-icon>
                {{ `${parameters.helpers.longest}"${parameters.seoCharCount.longestCity.text}"` }}
              </li>
            </ul>

            <div class="tw-mt-2">
              <code>{{ parameters.helpers.moreVariables }}</code>
            </div>
          </div>
          <v-spacer />
          <div>
            <div>
              <span class="tw-font-bold">
                {{ parameters.helpers.characters }}
              </span>
              <span :class="getTextColor(objKey, actualTextLenght.shortest)">{{ actualTextLenght.shortest }} </span>
              <span>{{ parameters.helpers.to }}</span>
              <span :class="getTextColor(objKey, actualTextLenght.longest)"> {{ actualTextLenght.longest }}</span>
            </div>
            <div class="tw-mt-2">
              <span class="tw-font-bold">{{ parameters.helpers.recommended }}</span>
              <span>{{
                `${objKey === 'locationMetaTitle' || objKey === 'metaTitle' ? metaTitle.min : metaDescr.min} ${
                  parameters.helpers.to
                } ${objKey === 'locationMetaTitle' || objKey === 'metaTitle' ? metaTitle.max : metaDescr.max}`
              }}</span>
            </div>
            <div class="tw-mt-2">
              <a
                href="https://www.notion.so/gtrsuite/Help-Center-4585cf09ca704585985755384e01d0ad "
                target="_blank"
                class="tw-flex tw-items-center"
              >
                <span class="tw-mr-1">{{ parameters.helpers.help }}</span>
                <v-icon small color="primary">{{ icons.mdiHelpCircleOutline }}</v-icon>
              </a>
            </div>
          </div>
        </div>
        <div v-else-if="objKey === 'metaDescription' || objKey === 'metaTitle'" class="modal-seo__helpers">
          <div>
            <span class="tw-font-bold">{{ parameters.helpers.title }}</span>
            <div class="tw-mt-2">
              <code>{{ parameters.helpers.location }}</code>
              <span> {{ `: ${parameters.seoCharCount.shortestName.length} ${parameters.helpers.char}` }}</span>
              <div>{{ `"${parameters.seoCharCount.longestName.text}"` }}</div>
            </div>
            <div class="tw-mt-2">
              <code>{{ parameters.helpers.city }}</code>
              <span>
                {{ `: ${parameters.seoCharCount.shortestCity.length} ${parameters.helpers.char}` }}
              </span>
              <div>
                {{ `"${parameters.seoCharCount.longestCity.text}"` }}
              </div>
            </div>
            <div class="tw-mt-2">
              <code>{{ parameters.helpers.moreVariables }}</code>
            </div>
          </div>
          <v-spacer />
          <div>
            <div>
              <span class="tw-font-bold">
                {{ parameters.helpers.characters }}
              </span>
              <span :class="getTextColor(objKey, actualTextLenght.shortest)"
                >{{ `${actualTextLenght.shortest}` }}
              </span>
            </div>
            <div class="tw-mt-2">
              <span class="tw-font-bold">{{ parameters.helpers.recommended }}</span>
              <span>{{
                `${objKey === 'locationMetaTitle' || objKey === 'metaTitle' ? metaTitle.min : metaDescr.min} ${
                  parameters.helpers.to
                } ${objKey === 'locationMetaTitle' || objKey === 'metaTitle' ? metaTitle.max : metaDescr.max}`
              }}</span>
            </div>
            <div class="tw-mt-2">
              <a
                href="https://www.notion.so/gtrsuite/Help-Center-4585cf09ca704585985755384e01d0ad "
                target="_blank"
                class="tw-flex tw-items-center"
              >
                <span class="tw-mr-1">{{ parameters.helpers.help }}</span>
                <v-icon small color="primary">{{ icons.mdiHelpCircleOutline }}</v-icon>
              </a>
            </div>
          </div>
        </div>
        <v-alert v-else text type="info" class="modal-seo__helpers">
          {{ parameters.helpers.title }}
          <br />
          {{ parameters.helpers.subtitle }}
        </v-alert>
      </div>
    </template>
  </modal>
</template>

<script>
import { mdiArrowRightThin, mdiHelpCircleOutline } from '@mdi/js'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiTextarea from '@/components/UI/Textarea.vue'

export default {
  name: 'ModalSeo',
  components: {
    UiDropdown,
    UiTextarea,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Object],
      required: true,
    },
    objKey: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        type: '',
        options: [],
      }),
    },
    seoCharCount: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      modelData: '',
      modelType: [],
      metaDescr: {
        min: 80,
        moy: 120,
        ok: 160,
        max: 170,
      },
      metaTitle: {
        min: 35,
        moy: 45,
        ok: 60,
        max: 65,
      },
      icons: {
        mdiArrowRightThin,
        mdiHelpCircleOutline,
      },
    }
  },
  computed: {
    actualTextLenght() {
      let actualTextSize = {
        shortest: 0,
        longest: 0,
      }
      if (this.modelData[this.modelType]) {
        actualTextSize = {
          shortest: this.modelData[this.modelType]
            .replace('{location}', '{LOCATION}')
            .replace('{city}', '{CITY}')
            .replace('{LOCATION}', this.seoCharCount.shortestName.text)
            .replace('{CITY}', this.seoCharCount.shortestCity.text).length,
          longest: this.modelData[this.modelType]
            .replace('{location}', '{LOCATION}')
            .replace('{city}', '{CITY}')
            .replace('{LOCATION}', this.seoCharCount.longestName.text)
            .replace('{CITY}', this.seoCharCount.longestCity.text).length,
        }
      }
      return actualTextSize
    },
  },
  mounted() {
    this.modelData = { ...this.data }
    this.modelType = this.parameters.type
  },
  methods: {
    getTextColor(type, textLength) {
      if (type === 'metaTitle' || type === 'locationMetaTitle') {
        if (textLength < this.metaTitle.min || textLength > this.metaTitle.max) {
          return 'red-text'
        } else if (textLength < this.metaTitle.moy || textLength > this.metaTitle.ok) {
          return 'orange-text'
        } else {
          return 'green-text'
        }
      } else if (type === 'metaDescription' || type === 'locationMetaDescription') {
        if (textLength < this.metaDescr.min || textLength > this.metaDescr.max) {
          return 'red-text'
        } else if (textLength < this.metaDescr.moy || textLength > this.metaDescr.ok) {
          return 'orange-text'
        } else {
          return 'green-text'
        }
      } else {
        return ''
      }
    },
    hasMaxLength(type) {
      return (
        type === 'locationMetaDescription' ||
        type === 'locationMetaTitle' ||
        type === 'metaDescription' ||
        type === 'metaTitle'
      )
    },
    save() {
      if (this.parameters.options) {
        this.$emit('save', {
          objKey: { [this.objKey]: this.modelData },
        })
      } else {
        this.$emit('save', {
          objKey: { [this.objKey]: this.modelData[this.modelType] },
        })
      }
    },
    closed() {
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-seo {
  &__helpers {
    @apply tw-mt-4 tw-mb-0 tw-flex;

    font-size: $font-size-default;

    &__list {
      @apply tw-list-none tw-p-0 tw-ml-4;
    }
  }

  &__dropdown {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }
  }
}
.green-text {
  color: $green-salem;
  font-weight: bold;
}
.red-text {
  color: $red-intense;
  font-weight: bold;
}

.orange-text {
  color: $orange-tree-poppy;
  font-weight: bold;
}
</style>
