const DragMixin = {
  data() {
    return {
      dragged: {
        index: null,
        grpIdx: null,
        start: false,
      },
      target: {
        index: null,
        grpIdx: null,
      },
    }
  },
  methods: {
    isDragOverMe(index, groupMod = false, grpIdx) {
      if (groupMod) {
        return (
          this.dragged.index !== null &&
          index === this.target.index &&
          index !== this.dragged.index &&
          this.dragged.grpIdx === this.target.grpIdx &&
          grpIdx === this.dragged.grpIdx
        )
      }
      return this.dragged.index !== null && index === this.target.index && index !== this.dragged.index
    },
    startDrag(evt, id, index, grpIdx = null, eventGroupId = null) {
      this.dragged.index = index
      this.dragged.grpIdx = grpIdx
      this.dragged.start = true
      this.$emit('dragGroupId', { init: true, id: eventGroupId })
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', id)
    },
    endDrag() {
      this.dragged.start = false
      this.dragged.index = null
      this.dragged.grpIdx = null
    },
    dragover(index, grpIdx = null) {
      this.target.index = index
      this.target.grpIdx = grpIdx
    },
    onDrop(event, eventGroupId = null, dataProperty = 'event') {
      this.$emit('dragGroupId', { init: false, id: eventGroupId })
      const currentItemId = event.dataTransfer.getData('itemID')
      let targetId = event.target.id
      targetId = targetId.split('_')[1]
      this.$emit(
        'updateOrder',
        {
          targetId,
          currentItemId,
          eventGroupId: eventGroupId,
        },
        dataProperty
      )
      this.endDrag()
    },
  },
}

export default DragMixin
