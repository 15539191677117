<template>
  <screen-grid class="location-general">
    <screen-card align="center" ratio="1-1" display="grid">
      <template v-slot:body>
        <screen-block
          ratio="1-1"
          :title="$t('locations.id.general.section.productLocator.title')"
          :helper="$t('locations.id.general.section.productLocator.helpers.tab')"
          align="center"
          :has-data="true"
          :class="{
            'location-general__status--active': activeProductLocator,
            'location-general__status--inactive': !activeProductLocator,
          }"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div v-else>
              <div class="location-general__status__icon backoffice-icons">delivery</div>
            </div>
          </template>
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="18px" margin-top="2px" width="100px" />
            <div class="location-general__status__state" v-else>
              <template v-if="activeProductLocator">
                {{ $t('locations.id.general.section.productLocator.status.active') }}
              </template>
              <template v-else-if="!activeProductLocator">
                {{ $t('locations.id.general.section.productLocator.status.inactive') }}
              </template>
            </div>
          </template>
          <template v-slot:right>
            <skeleton-line v-if="isLoading" height="18px" width="32px" margin-left="16px" />
            <ui-switch
              v-else
              class="location-general__status__toggle"
              id="active-locator"
              :standalone="true"
              :value="activeProductLocator"
              :input-value="activeProductLocator"
              :disabled="disableProductLocator"
              @input="updateProductLocator"
            ></ui-switch>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.general.section.productLocator.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('locations.id.productLocator.section.inventoryLocation.label.name')"
          icon="article"
          :has-data="typeof currentLocation.warehouse === 'string'"
          :data="currentLocation.warehouse"
          ratio="1-2"
          :is-loading="isLoading"
          :is-required="false"
          @click="editSimpleField('warehouse', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.productLocator.section.productCount.label.name')"
          icon="article"
          :has-data="typeof currentLocation.productCount === 'number'"
          :data="currentLocation.productCount"
          :is-editable="false"
          ratio="1-2"
          :is-loading="isLoading"
          :is-required="false"
        />
        <screen-block
          :helper="$t('locations.id.general.section.productLocator.helpers.email')"
          :title="$t('locations.id.general.section.productLocator.label.leadProductEmail')"
          icon="mail"
          :has-data="typeof currentLocation.leadProductEmail === 'string'"
          :data="currentLocation.leadProductEmail"
          ratio="1-2"
          :is-loading="isLoading"
          @click="editSimpleField('leadProductEmail', 'email', 'input', activeProductLocator)"
        />
        <screen-block
          :helper="$t('locations.id.general.section.productLocator.helpers.otherEmail')"
          :title="$t('locations.id.general.section.productLocator.label.leadProductGenerationsEmails')"
          icon="mail"
          :has-data="
            Array.isArray(currentLocation.leadProductGenerationsEmails) &&
            currentLocation.leadProductGenerationsEmails.length > 0
          "
          ratio="1-2"
          :is-loading="isLoading"
          @click="editList('leadProductGenerationsEmails', 'email', 'list', 'email')"
        >
          <template v-slot:data>
            <span v-for="(email, idx) in currentLocation.leadProductGenerationsEmails" :key="`email-${idx}`">
              {{ email.email
              }}<template v-if="idx < currentLocation.leadProductGenerationsEmails.length - 1">, </template>
            </span>
          </template>
        </screen-block>
        <screen-block
          :helper="$t('locations.id.general.section.productLocator.helpers.senderName')"
          :title="$t('locations.id.general.section.generalData.label.leadProductAuthorizeFrom')"
          icon="user"
          :has-data="typeof currentLocation.leadProductAuthorizeFrom === 'string'"
          :data="currentLocation.leadProductAuthorizeFrom"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('leadProductAuthorizeFrom', 'text', 'input')"
        />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import UiSwitch from '@/components/UI/Switch.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'

export default {
  name: 'ProductLocator',
  components: {
    UiSwitch,
    SkeletonLine,
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    SkeletonIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activeProductLocator() {
      return this.currentLocation.productLocatorStatus === '1'
    },
    disableProductLocator() {
      return !this.currentLocation.leadProductEmail || !this.currentLocation.warehouse
    },
  },
  methods: {
    editSimpleField(objKey, type, mode, required = false, number = null, options = [], dropdown = {}) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
          dropdown,
        },
      })
    },
    editList(objKey, type, mode, listKey = null) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          listKey,
        },
      })
    },
    updateProductLocator(value) {
      const productLocatorStatus = value ? '1' : '0'
      this.$emit('updateProductLocator', productLocatorStatus)
    },
  },
}
</script>

<style lang="scss" scoped>
.location-general {
  &__status {
    &__icon {
      margin-right: $gutter-mobile;
      border: 1px solid;
      border-radius: 50%;
      padding: 5px;
      width: 36px;
      height: 36px;

      .location-general__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-general__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }
    }

    &__state {
      margin-top: 2px;

      .location-general__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-general__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }
    }

    &__toggle {
      margin-left: $gutter-mobile;
    }
  }
}
</style>
