<template>
  <div
    class="ui-textarea"
    :class="{
      'ui-textarea--disabled': disabled,
      'ui-textarea--default': design === 'default',
    }"
  >
    <label
      :for="id"
      class="ui-textarea__label"
      :class="{
        'ui-textarea__label--error': error,
        'ui-textarea__label--full': design === 'full',
      }"
      v-if="design === 'full'"
    >
      {{ label }}
    </label>
    <div class="ui-textarea__wrapper">
      <textarea
        :id="id"
        class="ui-textarea__wrapper__field"
        :class="{
          'ui-textarea__wrapper__field--icon-left': icon && !reverse,
          'ui-textarea__wrapper__field--icon-right': icon && reverse,
          'ui-textarea__wrapper__field--error': error,
        }"
        :placeholder="design === 'minimalist' || design === 'full' ? label : ' '"
        @input="onInput($event.target.value)"
        :rows="rows"
        v-adjust-height
        :value="textareaValue"
        :autocomplete="autocomplete ? 'on' : 'off'"
        :disabled="disabled"
      />
      <span
        class="ui-textarea__wrapper__icon backoffice-icons"
        :class="{
          'ui-textarea__wrapper__icon--left': icon && !reverse,
          'ui-textarea__wrapper__icon--right': icon && reverse,
          'ui-textarea__wrapper__icon--error': error,
        }"
        v-if="icon"
      >
        {{ icon }}
      </span>
      <label
        :for="id"
        class="ui-textarea__wrapper__label"
        :class="{
          'ui-textarea__wrapper__label--icon-left': icon && !reverse,
          'ui-textarea__wrapper__label--icon-right': icon && reverse,
          'ui-textarea__wrapper__label--error': error,
          'ui-textarea__wrapper__label--hidden': design === 'minimalist',
        }"
        v-if="design !== 'full'"
      >
        {{ label }}
      </label>
    </div>
    <div class="ui-textarea__helper" :class="{ 'ui-textarea__helper--error': error }" v-if="helper || error">
      <slot>{{ helper }}</slot>
    </div>
  </div>
</template>

<script>
import { AdjustHeight } from '@/directives/adjustHeight.directive'

export default {
  name: 'UiTextarea',
  model: {
    prop: 'textareaValue',
  },
  props: {
    textareaValue: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    helper: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    design: {
      type: String,
      required: false,
      default: 'default',
    },
    rows: {
      type: Number,
      required: false,
      default: 5,
    },
    maxCharactersLength: {
      type: Number,
      required: false,
      default: null,
    },
  },
  directives: {
    AdjustHeight,
  },
  methods: {
    onInput(value) {
      /**
       * Emitted when the value of the textarea changed.
       * @event input
       * @type {Event}
       */
      if (this.maxCharactersLength != null && value.length > this.maxCharactersLength) {
        value = value.substring(0, this.maxCharactersLength)
        this.$forceUpdate()
      }
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-textarea {
  @include input;

  &__label {
    @include input-label;
  }

  &__wrapper {
    @include input-wrapper;

    &__field {
      @include input-field;
    }

    &__label {
      @include input-label;
    }

    &__icon {
      @include input-icon;
    }
  }

  &__helper {
    @include input-helper;
  }
}
</style>
