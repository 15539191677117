var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-clipper"},[(_vm.displayPreview && (_vm.picture || _vm.image.base64))?_c('div',{staticClass:"ui-clipper__container",on:{"drop":function($event){$event.preventDefault();return _vm.cropImg($event.dataTransfer.files[0])},"dragover":function($event){$event.preventDefault();}}},[_c('img',{staticClass:"ui-clipper__container__img",attrs:{"src":_vm.picture || _vm.image.base64,"alt":"preview"}}),(_vm.type !== 'locationPictures')?_c('div',{staticClass:"ui-clipper__container__actions"},[_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top',
          trigger: 'hover',
          content: _vm.$t('common.button.updateImg'),
          offset: 3,
        }),expression:"{\n          placement: 'top',\n          trigger: 'hover',\n          content: $t('common.button.updateImg'),\n          offset: 3,\n        }"}],staticClass:"ui-clipper__container__actions__edit",attrs:{"button":"cta","variant":"data","icon":"add_photo","icon-only":true,"label":_vm.$t('common.button.updateImg')},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.mediaImg.click()}}}),_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top',
          trigger: 'hover',
          content: _vm.$t('common.button.deleteImg'),
          offset: 3,
        }),expression:"{\n          placement: 'top',\n          trigger: 'hover',\n          content: $t('common.button.deleteImg'),\n          offset: 3,\n        }"}],staticClass:"ui-clipper__container__actions__delete",attrs:{"button":"cta","variant":"error","icon":"delete","icon-only":true,"label":_vm.$t('common.button.deleteImg')},on:{"click":function($event){$event.stopPropagation();return _vm.deleteImg.apply(null, arguments)}}})],1):_vm._e()]):_c('div',{staticClass:"ui-clipper__placeholder",on:{"click":function($event){$event.stopPropagation();return _vm.$refs.mediaImg.click()},"drop":function($event){$event.preventDefault();return _vm.cropImg($event.dataTransfer.files[0])},"dragover":function($event){$event.preventDefault();}}},[_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        placement: 'top',
        trigger: 'hover',
        content: _vm.$t('common.button.addImg'),
        offset: 3,
      }),expression:"{\n        placement: 'top',\n        trigger: 'hover',\n        content: $t('common.button.addImg'),\n        offset: 3,\n      }"}],staticClass:"ui-clipper__placeholder__add",attrs:{"button":"primary","variant":"data","icon":_vm.buttonIcon,"icon-only":true,"label":_vm.$t('common.button.addImg')},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.mediaImg.click()}}}),_c('div',{staticClass:"ui-clipper__placeholder__caption"},[_vm._v(" "+_vm._s(_vm.localizedPlaceholder || _vm.$t('modal.clipper.noPicture'))+" ")])],1),_c('input',{ref:"mediaImg",staticClass:"ui-clipper__file",attrs:{"type":"file","accept":_vm.acceptFiles},on:{"change":function($event){return _vm.cropImg($event.target.files[0])}}}),_c('modal-clipper',{attrs:{"image":_vm.image.preview},on:{"clip-picture":_vm.clipPicture}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }