<template>
  <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
  <screen-container direction="column" :no-header="true" class="screen-error">
    <div class="screen-error__number">404</div>
    <div class="screen-error__title">
      {{ $t('errors.404.title') }}
    </div>
    <div class="screen-error__description">
      {{ $t('errors.404.description') }}
    </div>
    <div class="screen-error__actions">
      <a href="/">
        {{ label }}
      </a>
    </div>
  </screen-container>
</template>

<script>
import ScreenContainer from '@/components/Screen/Container.vue'
import { URLS } from '@/config/urls.config'

export default {
  name: 'ScreenError',
  components: {
    ScreenContainer,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    URLS,
  }),
}
</script>

<style lang="scss" scoped>
.screen-error {
  align-items: center;
  justify-content: center;

  &__number {
    line-height: 1;
    font-size: 6rem;
    font-weight: 700;

    @media (min-width: $screen-sm) {
      font-size: 10rem;
    }
  }

  &__title {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;

    @media (min-width: $screen-sm) {
      font-size: 3rem;
    }
  }

  &__description {
    text-align: center;
    font-size: $font-size-md;
  }

  &__title,
  &__description,
  &__actions {
    margin-top: $gutter-tablet;

    @media (min-width: $screen-xl) {
      margin-top: $gutter-desktop;
    }
  }
}
</style>
