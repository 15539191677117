var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticClass:"modal-clipper",attrs:{"title":_vm.$t('modal.clipper.title'),"name":_vm.name,"has-apply":true,"apply-label":_vm.$t('common.button.finish'),"apply-variant":"data","hide-overlay":true,"height":"100%"},on:{"save":_vm.save},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('div',{staticClass:"modal-clipper__container"},[_c('div',{staticClass:"modal-clipper__container__preview"},[_c('clipper-basic',{ref:"imgClipper",staticClass:"modal-clipper__container__preview__clipper",attrs:{"src":_vm.image,"ratio":_vm.ratio,"rotate":_vm.rotate,"scale":_vm.scale,"init-width":100,"init-height":100,"bg-color":_vm.backgroundColor}})],1),_c('div',{staticClass:"modal-clipper__container__filters"},[_c('section',{staticClass:"modal-clipper__container__filters__section"},[_c('div',{staticClass:"modal-clipper__container__filters__section__title"},[_vm._v(" "+_vm._s(_vm.$t('modal.clipper.background.title'))+" ")]),_c('div',{staticClass:"modal-clipper__container__filters__section__block"},[_c('ui-radio',{attrs:{"value":"transparent","id":"bg-transparent"},model:{value:(_vm.backgroundColor),callback:function ($$v) {_vm.backgroundColor=$$v},expression:"backgroundColor"}},[_vm._v(" "+_vm._s(_vm.$t('modal.clipper.background.none'))+" ")]),_c('ui-radio',{attrs:{"value":"#fff","id":"bg-white"},model:{value:(_vm.backgroundColor),callback:function ($$v) {_vm.backgroundColor=$$v},expression:"backgroundColor"}},[_vm._v(" "+_vm._s(_vm.$t('modal.clipper.background.white'))+" ")]),_c('ui-radio',{attrs:{"value":"#000","id":"bg-black"},model:{value:(_vm.backgroundColor),callback:function ($$v) {_vm.backgroundColor=$$v},expression:"backgroundColor"}},[_vm._v(" "+_vm._s(_vm.$t('modal.clipper.background.black'))+" ")])],1)]),_c('section',{staticClass:"modal-clipper__container__filters__section"},[_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              placement: 'top',
              trigger: 'hover',
              content: _vm.$t('modal.clipper.button.zoomOut'),
              offset: 3,
            }),expression:"{\n              placement: 'top',\n              trigger: 'hover',\n              content: $t('modal.clipper.button.zoomOut'),\n              offset: 3,\n            }"}],staticClass:"modal-clipper__container__filters__section__zoom-out",attrs:{"label":_vm.$t('modal.clipper.button.zoomOut'),"button":"secondary","icon":"zoom_out","icon-only":true,"disabled":_vm.scale === 1},on:{"click":function($event){$event.preventDefault();return _vm.zoomOut.apply(null, arguments)}}}),_c('vue-slider',{staticClass:"modal-clipper__container__filters__section__slider",attrs:{"tooltip":"none","min":1,"max":5,"clickable":false,"dotSize":24,"interval":0.1,"enable-cross":false,"rail-style":{
              backgroundColor: '#ddd',
              height: '6px',
            },"dot-style":{
              backgroundColor: '#449afd',
              border: '2px solid #fff',
              boxShadow: 'none',
            },"process-style":{ backgroundColor: '#449afd' },"tooltip-style":{
              backgroundColor: '#449afd',
              borderColor: '#449afd',
              fontSize: '0.75rem',
              fontWeight: 'bold',
              padding: '4px 8px',
            }},model:{value:(_vm.scale),callback:function ($$v) {_vm.scale=$$v},expression:"scale"}}),_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              placement: 'top',
              trigger: 'hover',
              content: _vm.$t('modal.clipper.button.zoomIn'),
              offset: 3,
            }),expression:"{\n              placement: 'top',\n              trigger: 'hover',\n              content: $t('modal.clipper.button.zoomIn'),\n              offset: 3,\n            }"}],staticClass:"modal-clipper__container__filters__section__zoom-in",attrs:{"label":_vm.$t('modal.clipper.button.zoomIn'),"button":"secondary","icon":"zoom_in","icon-only":true,"disabled":_vm.scale === 5},on:{"click":function($event){$event.preventDefault();return _vm.zoomIn.apply(null, arguments)}}})],1),_c('section',{staticClass:"modal-clipper__container__filters__section"},[_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              placement: 'top',
              trigger: 'hover',
              content: _vm.$t('modal.clipper.button.rotateLeft'),
              offset: 3,
            }),expression:"{\n              placement: 'top',\n              trigger: 'hover',\n              content: $t('modal.clipper.button.rotateLeft'),\n              offset: 3,\n            }"}],staticClass:"modal-clipper__container__filters__section__rotate-left",attrs:{"label":_vm.$t('modal.clipper.button.rotateLeft'),"button":"secondary","icon":"rotate_left","icon-only":true,"disabled":_vm.rotate === -180},on:{"click":function($event){$event.preventDefault();return _vm.rotateLeft.apply(null, arguments)}}}),_c('vue-slider',{staticClass:"modal-clipper__container__filters__section__slider",attrs:{"tooltip":"none","min":-180,"max":180,"clickable":false,"dotSize":24,"interval":1,"enable-cross":false,"rail-style":{
              backgroundColor: '#ddd',
              height: '6px',
            },"dot-style":{
              backgroundColor: '#449afd',
              border: '2px solid #fff',
              boxShadow: 'none',
            },"process":false,"process-style":{ backgroundColor: '#449afd' },"tooltip-style":{
              backgroundColor: '#449afd',
              borderColor: '#449afd',
              fontSize: '0.75rem',
              fontWeight: 'bold',
              padding: '4px 8px',
            }},model:{value:(_vm.rotate),callback:function ($$v) {_vm.rotate=$$v},expression:"rotate"}}),_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              placement: 'top',
              trigger: 'hover',
              content: _vm.$t('modal.clipper.button.rotateRight'),
              offset: 3,
            }),expression:"{\n              placement: 'top',\n              trigger: 'hover',\n              content: $t('modal.clipper.button.rotateRight'),\n              offset: 3,\n            }"}],staticClass:"modal-clipper__container__filters__section__rotate-right",attrs:{"label":_vm.$t('modal.clipper.button.rotateRight'),"button":"secondary","icon":"rotate_right","icon-only":true,"disabled":_vm.rotate === 180},on:{"click":function($event){$event.preventDefault();return _vm.rotateRight.apply(null, arguments)}}})],1)])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }