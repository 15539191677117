var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"screen-block",class:[
    ("screen-block--" + _vm.ratio),
    { 'screen-block--is-editable': _vm.isEditable && _vm.isHover, 'screen-block--align-center': _vm.align === 'center' } ],style:(_vm.style),on:{"click":function($event){_vm.isEditable ? _vm.onClick() : false}}},[_vm._t("icon",function(){return [(_vm.isLoading)?_c('skeleton-icon',{attrs:{"margin-right":"16px"}}):(!_vm.isLoading && _vm.icon)?_c('div',[_c('v-icon',{staticClass:"screen-block__icon backoffice-icons",style:(_vm.iconStyle)},[_vm._v(_vm._s(_vm.icon))])],1):_vm._e()]}),_c('div',{staticClass:"screen-block__content"},[(_vm.isLoading)?_c('skeleton-line',{attrs:{"height":"18px"}}):_c('div',{staticClass:"screen-block__content__title"},[(_vm.isRequired && _vm.isRTL)?_c('span',[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.isRequired && !_vm.isRTL)?_c('span',[_vm._v("*")]):_vm._e(),(_vm.checkLink && !_vm.isValideLink)?_c('div',{staticClass:"screen-block__content__title__icon backoffice-icons"},[_vm._v("warning")]):_vm._e(),_c('ui-helper',{attrs:{"helper":_vm.helper}}),(_vm.isDataLoaded)?_c('ui-loader',{staticClass:"screen-block__content__loader",attrs:{"stroke":"#449afd","strokeWidth":4,"height":"17","width":"17"}}):_vm._e()],1),_c('div',{staticClass:"screen-block__content__data",class:{ 'screen-block__content__data--one-line': _vm.isOneLine }},[(_vm.isLoading)?_c('skeleton-line',{attrs:{"height":"18px","margin-top":"2px","width":"50%"}}):(_vm.hasData)?_vm._t("data",function(){return [(!_vm.isImage)?_c('div',{staticClass:"screen-block__content__data__text"},[_c('div',{staticClass:"screen-block__content__data__text__content",domProps:{"innerHTML":_vm._s(_vm.data)}}),(_vm.isLink)?_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              placement: 'top',
              trigger: 'hover',
              content: _vm.$t('common.button.externalLink'),
              offset: 3,
            }),expression:"{\n              placement: 'top',\n              trigger: 'hover',\n              content: $t('common.button.externalLink'),\n              offset: 3,\n            }"}],staticClass:"screen-block__content__data__text__link",attrs:{"label":_vm.$t('common.button.externalLink'),"button":"primary","icon":"external_link","icon-only":true,"variant":_vm.productLine,"is-link":true,"href":_vm.formatLink(_vm.data)},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_vm._e()],1):_c('div',{staticClass:"screen-block__content__data__img"},[_c('img',{staticClass:"screen-block__content__data__img__file",attrs:{"src":_vm.data}}),_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              placement: 'top',
              trigger: 'hover',
              content: _vm.$t('common.button.copyImg'),
              offset: 3,
            }),expression:"{\n              placement: 'top',\n              trigger: 'hover',\n              content: $t('common.button.copyImg'),\n              offset: 3,\n            }"}],staticClass:"screen-block__content__data__img__copy",attrs:{"label":_vm.$t('common.button.copyImg'),"button":"primary","icon":"copy","icon-only":true,"variant":_vm.productLine},on:{"click":function($event){$event.stopPropagation();return _vm.copyLink(_vm.data)}}})],1)]}):_c('em',{domProps:{"innerHTML":_vm._s(_vm.$t('common.label.noData'))}})],2)],1),_vm._t("right")],2)}
var staticRenderFns = []

export { render, staticRenderFns }