var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-grid',{staticClass:"location-lpe-gmb"},[_c('screen-card',{attrs:{"title":_vm.$t('locations.id.gmb.section.extraData.gmb.title'),"ratio":"1-1","overflow-hidden":true,"display":"grid","is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('screen-block',{attrs:{"title":_vm.$t('locations.id.gmb.section.extraData.gmb.label'),"icon":"article","has-data":_vm.gmbBindLocations.length > 0,"ratio":"1-1","is-loading":_vm.isLoading,"is-editable":true},on:{"click":function($event){return _vm.editSimpleField(
            'ids',
            'text',
            'list',
            false,
            null,
            _vm.locationsSearch,
            {
              label: 'location_name',
              customLabel: 'location_name store_code',
              trackBy: 'place_id',
            },
            false
          )}},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('div',{staticClass:"location-lpe-gmb__locations"},_vm._l((_vm.gmbBindLocations),function(location,idx){return _c('span',{key:idx,staticClass:"location-lpe-gmb__locations__item"},[_vm._v(" "+_vm._s(location.location_name)+" ")])}),0)]},proxy:true}])}),_c('screen-block',{attrs:{"title":_vm.$t('locations.id.gmb.section.extraData.gpbId.label'),"icon":"article","has-data":true,"data":_vm.currentLocation.gpbId,"is-editable":false,"ratio":"1-2","is-loading":_vm.isLoading,"is-required":false},scopedSlots:_vm._u([{key:"data",fn:function(){return [(_vm.currentLocation.gpbId)?_c('span',[_vm._v(_vm._s(_vm.currentLocation.gpbId))]):_c('span',[_vm._v(_vm._s(_vm.$t('common.button.no')))])]},proxy:true}])}),_c('screen-block',{attrs:{"title":_vm.$t('locations.id.gmb.section.extraData.placeId.label'),"icon":"attribut","has-data":!!_vm.currentLocation.placeId,"data":_vm.currentLocation.placeId,"is-editable":false,"ratio":"1-2","is-loading":_vm.isLoading,"is-required":false},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('router-link',{attrs:{"target":"_blank","to":("//search.google.com/local/writereview?placeid=" + (_vm.currentLocation.placeId))}},[_vm._v(_vm._s(_vm.currentLocation.placeId))])]},proxy:true}])}),_c('screen-block',{attrs:{"title":_vm.$t('locations.id.gmb.section.extraData.gmbSheet.label'),"icon":"attribut","has-data":!!_vm.currentLocation.account,"data":_vm.currentLocation.account,"is-editable":false,"ratio":"1-2","is-loading":_vm.isLoading,"is-required":false},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('router-link',{attrs:{"target":"_blank","to":{ name: 'LocationGmb', params: { name: '' } }}},[_vm._v(" "+_vm._s(_vm.currentLocation.account)+" ")])]},proxy:true}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }