<template>
  <screen-grid class="location-general">
    <screen-card align="center" :ratio="activeLocationGroupWithoutStatus ? '1-2' : '1-1'" display="grid">
      <template v-slot:body>
        <screen-block
          ratio="1-1"
          :title="$t('locations.id.general.section.activeLocation.label')"
          align="center"
          :has-data="true"
          :class="{
            'location-general__status--active': activeLocation,
            'location-general__status--inactive': !activeLocation,
          }"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div v-else>
              <div class="location-general__status__icon backoffice-icons">location</div>
            </div>
          </template>
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="18px" margin-top="2px" width="100px" />
            <div class="location-general__status__state" v-else>
              <template v-if="activeLocation">
                {{ $t('locations.id.general.section.activeLocation.status.active') }}
              </template>
              <template v-else-if="!activeLocation">
                {{ $t('locations.id.general.section.activeLocation.status.inactive') }}
              </template>
            </div>
            <div class="location-general__status__date">
              <div>
                {{ $t('locations.id.general.section.date.createdAt', { createdAt: date(currentLocation.createdAt) }) }}
              </div>
              <div>
                {{ $t('locations.id.general.section.date.updatedAt', { updatedAt: date(currentLocation.updatedAt) }) }}
              </div>
            </div>
          </template>
          <template v-slot:right>
            <skeleton-line v-if="isLoading" height="18px" width="32px" margin-left="16px" />
            <ui-switch
              v-else
              class="location-general__status__toggle"
              id="active-location"
              :standalone="true"
              :value="activeLocation"
              :input-value="activeLocation"
              @input="updateLocationStatus"
            ></ui-switch>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card align="center" ratio="1-2" display="grid" v-if="activeLocationGroupWithoutStatus">
      <template v-slot:body>
        <screen-block
          ratio="1-1"
          :title="$t('locations.id.general.section.activeLocationGroupStatus.label')"
          align="center"
          :has-data="true"
          :class="{
            'location-general__status--active': activeLocationGroupStatus,
            'location-general__status--inactive': !activeLocationGroupStatus,
          }"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div v-else>
              <div class="location-general__status__icon backoffice-icons">hours_group</div>
            </div>
          </template>
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="18px" margin-top="2px" width="100px" />
            <div class="location-general__status__state" v-else>
              <template v-if="activeLocationGroupStatus">
                {{ $t('locations.id.general.section.activeLocation.status.active') }}
              </template>
              <template v-else-if="!activeLocationGroupStatus">
                {{ $t('locations.id.general.section.activeLocation.status.inactive') }}
              </template>
            </div>
          </template>
          <template v-slot:right>
            <skeleton-line v-if="isLoading" height="18px" width="32px" margin-left="16px" />
            <ui-switch
              v-else
              class="location-general__status__toggle"
              id="active-location-group"
              :standalone="true"
              :value="activeLocationGroupStatus"
              :input-value="activeLocationGroupStatus"
              @input="updateLocationGroupStatus"
            ></ui-switch>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.general.section.generalData.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.name')"
          icon="article"
          :has-data="typeof currentLocation.name === 'string'"
          :data="currentLocation.name"
          ratio="1-3"
          :is-loading="isLoading"
          :is-required="true"
          @click="editSimpleField('name', 'text', 'input', true)"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.company')"
          icon="article"
          :has-data="typeof currentLocation.company === 'string' && currentLocation.company !== ''"
          :data="currentLocation.company"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('company', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.locationType')"
          icon="attribut"
          :is-required="true"
          :has-data="typeof currentLocation.locationType === 'string'"
          :data="currentLocation.locationType"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('locationType', 'text', 'choice', false, null, currentClient.locationTypes)"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.id')"
          icon="business"
          :has-data="typeof currentLocation.externalId === 'string'"
          :data="currentLocation.externalId"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('externalId', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.gatoreviewsId')"
          :is-required="true"
          icon="attribut"
          :has-data="typeof currentLocation.gatoreviewsId === 'string' && currentLocation.gatoreviewsId !== ''"
          :data="currentLocation.gatoreviewsId"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('gatoreviewsId', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.group')"
          icon="article"
          :has-data="typeof currentLocation.group === 'string' && currentLocation.group !== ''"
          :data="currentLocation.group"
          ratio="1-3"
          :is-loading="isLoading"
          :is-data-loaded="isLocationGroupsDataLoaded"
          @click="getGroupsBeforeOpenModal('group', 'text', 'singleList', false, null)"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.address')"
          :is-required="true"
          icon="pin"
          :has-data="typeof currentLocation.street1 === 'string'"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editAddress"
        >
          <template v-slot:data>
            <div>
              {{ currentLocation.street1 }}
            </div>
            <div v-if="currentLocation.street2">
              {{ currentLocation.street2 }}
            </div>
            <div>
              <span v-if="currentLocation.postalCode">
                {{ currentLocation.postalCode }}
              </span>
              {{ currentLocation.city }}
            </div>
            <div>
              <span v-if="currentLocation.countryCode">
                {{ mapCountry(currentLocation.countryCode) }}
              </span>
            </div>
          </template>
        </screen-block>
        <screen-block
          class="location-general__coordinates"
          :is-required="true"
          :title="$t('locations.id.general.section.generalData.label.gps')"
          icon="geoloc"
          :has-data="typeof currentLocation.latitude === 'number' && typeof currentLocation.longitude === 'number'"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editCoordinates"
        >
          <template v-slot:data>
            <div class="location-general__coordinates__section">
              <div class="location-general__coordinates__section__label">
                {{ $t('common.label.latitude') }}
              </div>
              <div class="location-general__coordinates__section__value">
                {{ currentLocation.latitude }}
              </div>
            </div>
            <div class="location-general__coordinates__section">
              <div class="location-general__coordinates__section__label">
                {{ $t('common.label.longitude') }}
              </div>
              <div class="location-general__coordinates__section__value">
                {{ currentLocation.longitude }}
              </div>
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.subDivisions')"
          icon="world"
          :is-required="true"
          :has-data="hasSubdivisions"
          :is-editable="hasCountry"
          ratio="1-3"
          :helper="!hasCountry ? $t('locations.id.general.section.generalData.tooltip.subDivisions') : null"
          :is-loading="isLoading"
          @click="editSubDivisions"
        >
          <template v-slot:data>
            <div v-if="currentLocation.subDivision1">
              {{ mapSubDivision(currentLocation.subDivision1, 'level1') }}
            </div>
            <div v-if="currentLocation.subDivision2">
              {{ mapSubDivision(currentLocation.subDivision2, 'level2') }}
            </div>
            <div v-if="currentLocation.subDivision3">
              {{ mapSubDivision(currentLocation.subDivision3, 'level3') }}
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.phone')"
          icon="phone"
          :has-data="typeof currentLocation.phone === 'string' && currentLocation.phone !== ''"
          :data="currentLocation.phone"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editPhone()"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.nationalPhone')"
          icon="phone"
          :has-data="typeof currentLocation.nationalPhone === 'string' && currentLocation.nationalPhone !== ''"
          :data="currentLocation.nationalPhone"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('nationalPhone', 'phone', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.internationalPhone')"
          icon="phone"
          :has-data="typeof currentLocation.internationalPhone === 'string'"
          :data="currentLocation.internationalPhone"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('internationalPhone', 'phone', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.phoneInformation')"
          icon="phone"
          :has-data="typeof currentLocation.phoneInformation === 'string'"
          :data="currentLocation.phoneInformation"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('phoneInformation', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.website')"
          icon="link"
          :has-data="typeof websiteUrl === 'string'"
          :data="websiteUrl"
          ratio="1-3"
          :is-link="true"
          :is-loading="isLoading"
          @click="editWebsite"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.customLink')"
          icon="link"
          :has-data="typeof currentLocation.customLink === 'string'"
          :data="currentLocation.customLink"
          ratio="1-3"
          :is-link="true"
          :is-loading="isLoading"
          @click="editSimpleField('customLink', 'url', 'input')"
        />
        <screen-block
          :helper="$t('locations.id.general.section.generalData.helpers.email')"
          :title="$t('locations.id.general.section.generalData.label.email')"
          icon="mail"
          :has-data="typeof currentLocation.email === 'string'"
          :data="currentLocation.email"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('email', 'email', 'input')"
        />
        <screen-block
          :helper="$t('locations.id.general.section.generalData.helpers.otherEmail')"
          :title="$t('locations.id.general.section.generalData.label.leadGenerationsEmails')"
          icon="mail"
          :has-data="
            Array.isArray(currentLocation.leadGenerationsEmails) && currentLocation.leadGenerationsEmails.length > 0
          "
          ratio="1-3"
          :is-loading="isLoading"
          @click="
            editList(
              'leadGenerationsEmails',
              'email',
              'list',
              'email',
              $t('locations.id.general.section.generalData.description.leadGenerationsEmails')
            )
          "
        >
          <template v-slot:data>
            <span v-for="(email, idx) in currentLocation.leadGenerationsEmails" :key="`email-${idx}`">
              {{ email.email }}<template v-if="idx < currentLocation.leadGenerationsEmails.length - 1">, </template>
            </span>
          </template>
        </screen-block>
        <screen-block
          :helper="$t('locations.id.general.section.generalData.helpers.senderName')"
          :title="$t('locations.id.general.section.generalData.label.emailAuthorizeFrom')"
          icon="user"
          :has-data="typeof currentLocation.emailAuthorizeFrom === 'string'"
          :data="currentLocation.emailAuthorizeFrom"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('emailAuthorizeFrom', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.tags')"
          icon="attribut"
          :has-data="Array.isArray(currentLocation.tags) && currentLocation.tags.length > 0"
          ratio="1-3"
          :is-loading="isLoading"
          @click="
            editSimpleField('tags', 'text', 'list', false, null, availableFrontOffices, {
              label: 'name',
              trackBy: 'id',
              customTag: true,
            })
          "
          v-if="availableFrontOffices.length > 1"
        >
          <template v-slot:data>
            <span v-for="(tag, idx) in frontOfficesTags" :key="`location-tag-${idx}`">
              {{ tag.name }}<template v-if="idx < currentLocation.tags.length - 1">, </template>
            </span>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.slug')"
          icon="link"
          :has-data="typeof currentLocation.slug === 'string'"
          :data="currentLocation.slug"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('slug', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.openingHoursInformation')"
          icon="hours"
          :has-data="typeof currentLocation.openingHoursInformation === 'string'"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('openingHoursInformation', 'text', 'textarea')"
        >
          <template v-slot:data>
            <pre class="location-general__opening-hours-information">{{ currentLocation.openingHoursInformation }}</pre>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.openDate')"
          icon="hours_group"
          :has-data="typeof currentLocation.openDate === 'string'"
          :data="date(currentLocation.openDate)"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('openDate', 'text', 'datepicker')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.closeDate')"
          icon="hours_group"
          :has-data="typeof currentLocation.closeDate === 'string'"
          :data="date(currentLocation.closeDate)"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('closeDate', 'text', 'datepicker')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.timezoneId')"
          :icon="icons.mdiMapClock"
          :has-data="typeof currentLocation.timezoneId === 'string' && currentLocation.timezoneId !== ''"
          :data="currentLocation.timezoneId"
          ratio="1-3"
          :is-loading="isLoading"
          @click="
            editSimpleField('timezoneId', 'text', 'singleList', false, null, timezones, {
              groupLabel: 'country',
              groupValues: 'timezones',
            })
          "
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.collectFormId')"
          icon="article"
          :has-data="!!currentLocation.collectFormId"
          :data="currentLocation.collectFormId"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editList('collectFormId', 'text', 'input')"
        />
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.general.section.openingHours.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
      v-if="activeLocationGroup"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.ohGroup')"
          icon="hours_group"
          :has-data="typeof currentOhGroup.name === 'string'"
          ratio="1-1"
          :is-loading="isLoading"
          :isEditable="locationCanUpdateOpeningHoursGroup"
          @click="
            editOhGroupId('ohGroup', 'text', 'choice', true, null, ohGroupsCorrectCountries, {
              label: 'name',
              trackBy: 'id',
            })
          "
        >
          <template v-slot:data>
            <div>
              <span>{{ currentOhGroup.name }}</span>
              <div v-if="!locationCanUpdateOpeningHoursGroup" class="location-general__oh-group-notification">
                <span>
                  {{ $t('locations.id.general.section.generalData.label.ohGroupNotification') }}
                </span>
                <span class="location-general__oh-group-notification__icon backoffice-icons"> warning </span>
              </div>
            </div>
          </template>
        </screen-block>
        <ui-slider
          :slides="ohSubgroups"
          v-if="ohSubgroups.length > 0 && !isLoading"
          @initialized="sliderInitialized"
          ref="sliderOhSubgroup"
        >
          <template v-slot:slide="slotProps">
            <div class="location-general__hours--title">
              <skeleton-line v-if="isLoading" height="18px" width="150px" />
              <span v-else class="location-general__hours--title__label">{{ slotProps.slide.name }}</span>
              <skeleton-line
                v-if="isLoading && slotProps.slide.id === currentLocation.openingHoursSubGroupId"
                height="16px"
                width="60px"
              />
              <v-chip
                v-else-if="slotProps.slide.id === currentLocation.openingHoursSubGroupId"
                class="tw-ml-3"
                color="success"
                label
                x-small
              >
                <v-icon left x-small> {{ icons.mdiCheck }} </v-icon>
                {{ $t('locations.id.general.section.generalData.label.ohGroupActive') }}
              </v-chip>
            </div>
            <screen-block
              class="location-general__hours"
              icon="hours"
              :title="
                slotProps.slide.label
                  ? slotProps.slide.label
                  : $tc('locations.id.general.section.openingHours.label.hourSet', 2, {
                      position: slotProps.slide.position,
                    })
              "
              :is-editable="false"
              :is-loading="isLoading"
              :has-data="ohSubgroups.length > 0"
              ratio="1-1"
            >
              <template v-slot:data>
                <div
                  class="location-general__hours__slot"
                  v-for="(slots, day, idx) in slotProps.slide.hours"
                  :key="`opening-hour-${idx}`"
                >
                  <div class="location-general__hours__slot__day">
                    {{ $t(`common.days.${day}`) }}
                  </div>
                  <div class="location-general__hours__slot__time">
                    <div v-if="slots.length === 0">
                      {{ $t('common.label.closed') }}
                    </div>
                    <div
                      v-else-if="
                        slots.length === 1 && slots[0].split('-')[0] === '0:00' && slots[0].split('-')[1] === '0:00'
                      "
                    >
                      {{ $t('common.label.h24') }}
                    </div>
                    <div v-else v-for="(slot, j) in slots" :key="`opening-hour-time-${j}`">
                      {{ convertTime(slot.split('-')[0]) }} - {{ convertTime(slot.split('-')[1]) }}
                    </div>
                  </div>
                </div>
              </template>
            </screen-block>
          </template>
        </ui-slider>
        <screen-action
          :label="$t('locations.id.general.section.exceptionalOpeningHours.ohgroup.button.select')"
          icon="add_hours"
          :is-loading="isLoading"
          :is-updating="isUpdateOpeningHour"
          :disabled="!hasLocationGroupId || !locationCanUpdateOpeningHoursGroup"
          @click="saveOhSubgroup"
        />
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.general.section.openingHours.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
      v-else
    >
      <template v-slot:body>
        <ui-slider :slides="currentLocationOpeningHours" v-if="currentLocationOpeningHours.length > 0">
          <template v-slot:slide="slotProps">
            <screen-block
              class="location-general__hours"
              :title="
                slotProps.slide.label
                  ? slotProps.slide.label
                  : $tc('locations.id.general.section.openingHours.label.hourSet', 2, {
                      position: slotProps.slide.position,
                    })
              "
              icon="hours"
              :has-data="currentLocationOpeningHours.length > 0"
              ratio="1-1"
              :is-loading="isLoading"
              @click="editOpeningHours(slotProps.slide)"
            >
              <template v-slot:data>
                <div
                  class="location-general__hours__slot"
                  v-for="(slots, day, idx) in slotProps.slide.hours"
                  :key="`opening-hour-${idx}`"
                >
                  <div class="location-general__hours__slot__day">
                    {{ $t(`common.days.${day}`) }}
                  </div>
                  <div class="location-general__hours__slot__time">
                    <div v-if="slots.length === 0">
                      {{ $t('common.label.closed') }}
                    </div>
                    <div
                      v-else-if="
                        slots.length === 1 && slots[0].split('-')[0] === '0:00' && slots[0].split('-')[1] === '0:00'
                      "
                    >
                      {{ $t('common.label.h24') }}
                    </div>
                    <div v-else v-for="(slot, j) in slots" :key="`opening-hour-time-${j}`">
                      {{ convertTime(slot.split('-')[0]) }} - {{ convertTime(slot.split('-')[1]) }}
                    </div>
                  </div>
                </div>
              </template>
            </screen-block>
          </template>
        </ui-slider>
        <screen-block
          v-else
          :title="$tc('locations.id.general.section.openingHours.label.hourSet', 1)"
          icon="hours"
          :has-data="currentLocationOpeningHours.length > 0"
          class="location-general__hours"
          ratio="1-1"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-action
          :label="$t('locations.id.general.section.openingHours.button.addSet')"
          icon="add_hours"
          :is-loading="isLoading"
          @click="editOpeningHours()"
        />
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.general.section.exceptionalOpeningHours.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:header-right>
        <skeleton-line v-if="isLoading" height="18px" width="32px" margin-left="16px" />
        <ui-dropdown
          class="location-general__dropdown"
          id="dropdown-exceptional-hours-period"
          v-else
          v-model="exceptionalHoursPeriod"
          track-by="id"
          label="label"
          :options="exceptionalHoursPeriodOptions"
          :placeholder="$t('locations.id.general.section.exceptionalOpeningHours.period.placeholder')"
          @input="sortExceptionalOpeningHour"
        />
      </template>
      <template v-slot:body>
        <div v-if="!isLoading && !locationCanUpdateOpeningHoursGroup" class="location-general__eoh-group-notification">
          <span>
            {{ $t('locations.id.general.section.generalData.label.eohGroupNotification') }}
          </span>
          <span class="location-general__eoh-group-notification__icon backoffice-icons"> warning </span>
        </div>
        <screen-block
          v-for="(exceptionalHour, idx) in currentLocationExceptionalOpeningHours"
          :key="`exceptional-hour-${idx}`"
          :title="exceptionalHour.label"
          icon="date"
          :has-data="currentLocationExceptionalOpeningHours.length > 0"
          class="location-general__hours"
          ratio="1-1"
          :is-loading="isLoading || isUpdateExceptionalOpeningHour"
          @click="locationCanUpdateOpeningHoursGroup && editExceptionalHours(exceptionalHour)"
        >
          <template v-slot:data>
            <div class="location-general__hours__slot">
              <div class="location-general__hours__slot__day">
                <template
                  v-if="
                    exceptionalHour.dateFrom === exceptionalHour.dateTo ||
                    (exceptionalHour.dateFrom && !exceptionalHour.dateTo)
                  "
                >
                  {{ $t('common.date.on') }} {{ date(exceptionalHour.dateFrom) }}
                </template>
                <template v-else>
                  {{ $t('common.date.from') }} {{ date(exceptionalHour.dateFrom) }} {{ $t('common.date.to') }}
                  {{ date(exceptionalHour.dateTo) }}
                </template>
              </div>
              <div class="location-general__hours__slot__time">
                <div v-if="!exceptionalHour.openStatus">
                  {{ $t('common.label.closed') }}
                </div>
                <div v-else-if="!exceptionalHour.hours1 && !exceptionalHour.hours2">
                  {{ $t('common.label.open') }}
                </div>
                <template v-else>
                  <div v-if="exceptionalHour.hours1">
                    {{ convertTime(exceptionalHour.hours1.split('-')[0]) }} -
                    {{ convertTime(exceptionalHour.hours1.split('-')[1]) }}
                  </div>
                  <div v-if="exceptionalHour.hours2">
                    {{ convertTime(exceptionalHour.hours2.split('-')[0]) }} -
                    {{ convertTime(exceptionalHour.hours2.split('-')[1]) }}
                  </div>
                </template>
              </div>
            </div>
          </template>
        </screen-block>
        <screen-block
          v-if="currentLocationExceptionalOpeningHours.length === 0"
          :title="$tc('locations.id.general.section.exceptionalOpeningHours.label.hourSet', 1)"
          icon="date"
          :has-data="true"
          ratio="1-1"
          :is-loading="isLoading || isUpdateExceptionalOpeningHour"
          :is-editable="false"
        >
          <template v-slot:data>
            <div v-if="exceptionalHoursPeriod.id === 'all'">
              {{ $t('locations.id.general.section.exceptionalOpeningHours.period.noData.all') }}
            </div>
            <div v-else-if="exceptionalHoursPeriod.id === 'future'">
              {{ $t('locations.id.general.section.exceptionalOpeningHours.period.noData.future') }}
            </div>
            <div v-else-if="exceptionalHoursPeriod.id === 'deprecated'">
              {{ $t('locations.id.general.section.exceptionalOpeningHours.period.noData.deprecated') }}
            </div>
            <div v-else>
              {{ $t('locations.id.general.section.exceptionalOpeningHours.period.noData.current') }}
            </div>
          </template>
        </screen-block>
        <screen-action
          :label="$t('locations.id.general.section.exceptionalOpeningHours.button.addSet')"
          icon="add_hours"
          :is-loading="isLoading"
          :disabled="!locationCanUpdateOpeningHoursGroup"
          @click="editExceptionalHours()"
        />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import timezones from '@/config/timezones.config'
import UiSwitch from '@/components/UI/Switch.vue'
import UiSlider from '@/components/UI/Slider.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ScreenAction from '@/components/Screen/Action.vue'
import { formatedDate } from '@/utils/date.util'
import { convert24hto12h } from '@/utils/hours.util'
import { mdiCheck, mdiMapClock } from '@mdi/js'
import { getClientGroups } from '@/services/location.service'

export default {
  name: 'LocationGeneral',
  components: {
    UiSwitch,
    UiSlider,
    UiDropdown,
    SkeletonLine,
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    ScreenAction,
    SkeletonIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    currentLocationUrls: {
      type: Array,
      required: true,
    },
    currentLocationOpeningHours: {
      type: Array,
      required: true,
    },
    currentLocationExceptionalOpeningHours: {
      type: Array,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    currentOhGroup: {
      type: Object,
      required: false,
      default: () => {},
    },
    countries: {
      type: Array,
      required: true,
    },
    availableFrontOffices: {
      type: Array,
      required: true,
    },
    frontOffices: {
      type: Array,
      required: true,
    },
    subDivisions: {
      type: [Object, Array],
      required: false,
      default: null,
    },
    externalModules: {
      type: Object,
      required: false,
      default: null,
    },
    ohGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    ohSubgroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    isUpdateExceptionalOpeningHour: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpdateOpeningHour: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiCheck,
        mdiMapClock,
      },
      activeGmbPage: false,
      exceptionalHoursPeriod: {
        id: 'future',
        label: this.$t('locations.id.general.section.exceptionalOpeningHours.period.future'),
      },
      exceptionalHoursPeriodOptions: [
        { id: 'current', label: this.$t('locations.id.general.section.exceptionalOpeningHours.period.current') },
        { id: 'deprecated', label: this.$t('locations.id.general.section.exceptionalOpeningHours.period.deprecated') },
        { id: 'future', label: this.$t('locations.id.general.section.exceptionalOpeningHours.period.future') },
        { id: 'all', label: this.$t('locations.id.general.section.exceptionalOpeningHours.period.all') },
      ],
      isLocationGroupsDataLoaded: false,
      timezones,
    }
  },
  watch: {
    currentLocation: {
      deep: true,
      handler() {
        if (this.activeLocationGroup) {
          this.sliderInitialized()
        }
        this.sortExceptionalOpeningHour('future')
      },
    },
  },
  computed: {
    frontOfficesTags() {
      let frontOfficesTagsList = []
      this.currentLocation.tags.forEach(currentLocationTag => {
        this.availableFrontOffices.filter(frontOfficesTags =>
          frontOfficesTags.tag === currentLocationTag ? frontOfficesTagsList.push(frontOfficesTags) : null
        )
      })
      return frontOfficesTagsList
    },
    activeLocation() {
      if (this.currentLocation.status === '0') {
        return false
      }
      return true
    },
    websiteUrl() {
      const url = this.currentLocationUrls.find(url => url.source === 'Website')
      return url ? url.sourceUrl : null
    },
    currentLocationUrlsWithoutWebsite() {
      return this.currentLocationUrls.filter(url => url.source !== 'Website')
    },
    hasCountry() {
      return typeof this.currentLocation.countryCode === 'string' && this.currentLocation.countryCode !== ''
    },
    hasSubdivisions() {
      return (
        typeof this.currentLocation.subDivision1 === 'string' &&
        this.currentLocation.subDivision1 !== '' &&
        this.subDivisions !== null
      )
    },
    activeLocationGroupWithoutStatus() {
      return this.currentClient.locationOpeningHoursStatus === '1' && this.ohGroupsCorrectCountries.length > 0
    },
    activeLocationGroup() {
      return (
        this.currentClient.locationOpeningHoursStatus === '1' &&
        this.ohGroupsCorrectCountries.length > 0 &&
        this.activeLocationGroupStatus
      )
    },
    activeLocationGroupStatus() {
      return this.currentLocation.openingHoursGroupExcluded !== '1'
    },
    ohGroupsCorrectCountries() {
      return this.ohGroups.filter(ohGroup => ohGroup.countries.includes(this.currentLocation.countryCode))
    },
    hasLocationGroupId() {
      return this.currentLocation.openingHoursGroupId !== null && this.currentLocation.openingHoursGroupId !== 0
    },
    locationCanUpdateOpeningHoursGroup() {
      return this.currentLocation?.canUpdateOpeningHoursGroup === '1'
    },
  },
  methods: {
    async getGroupsBeforeOpenModal(objKey, type, mode, required, number) {
      this.isLocationGroupsDataLoaded = true
      const { data } = await getClientGroups(this.currentClient.id)
      const mergeGroups = [...data.results.filter(group => group.value).map(group => group.value)]
      if (this.currentLocation.group && !mergeGroups.includes(this.currentLocation.group)) {
        mergeGroups.push(this.currentLocation.group)
      }
      this.isLocationGroupsDataLoaded = false
      this.editSimpleField(
        objKey,
        type,
        mode,
        required,
        number,
        mergeGroups,
        { isGroup: true },
        this.$t('locations.id.general.section.generalData.description.groupInternal')
      )
    },
    editSimpleField(
      objKey,
      type,
      mode,
      required = false,
      number = null,
      options = [],
      dropdown = {},
      description = ''
    ) {
      this.$emit('editSimpleField', {
        objKey,
        description,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
          dropdown,
        },
      })
    },
    editOhGroupId(objKey, type, mode, required = false, number = null, options = [], dropdown = {}) {
      this.$emit('editOhGroupId', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
          dropdown,
        },
      })
    },
    editList(objKey, type, mode, listKey = null, description = '') {
      this.$emit('editSimpleField', {
        objKey,
        description,
        parameters: {
          type,
          mode,
          listKey,
        },
      })
    },
    editAddress() {
      this.$emit('editAddress', {
        address: {
          street1: this.currentLocation.street1,
          street2: this.currentLocation.street2,
          postalCode: this.currentLocation.postalCode,
          city: this.currentLocation.city,
          countryCode: this.currentLocation.countryCode,
        },
      })
    },
    editPhone() {
      this.$emit('editPhone', {
        phone: this.currentLocation.phone,
        countryCode: this.currentLocation.countryCode,
      })
    },
    editCoordinates() {
      this.$emit('editCoordinates', {
        coordinates: {
          street1: this.currentLocation.street1,
          street2: this.currentLocation.street2,
          postalCode: this.currentLocation.postalCode,
          city: this.currentLocation.city,
          countryCode: this.currentLocation.countryCode,
          latitude: this.currentLocation.latitude,
          longitude: this.currentLocation.longitude,
        },
      })
    },
    editOpeningHours(hourSet) {
      this.$emit('editOpeningHours', {
        hours: hourSet || {
          position: this.currentLocationOpeningHours.length + 1,
          locationId: this.currentLocation.id,
        },
      })
    },
    editWebsite() {
      const url = this.currentLocationUrls.find(url => url.source === 'Website')

      this.$emit('editWebsite', {
        url: url || { source: 'Website', sourceUrl: '', locationId: this.currentLocation.id },
      })
    },
    editExceptionalHours(exceptionalHour) {
      this.$emit('editExceptionalHours', {
        exceptionalHour: exceptionalHour || { locationId: this.currentLocation.id },
      })
    },
    editSubDivisions() {
      this.$emit('editSubDivisions', {
        subDivisions: this.subDivisions,
        locationDivisions: {
          subDivision1: this.currentLocation.subDivision1,
          subDivision2: this.currentLocation.subDivision2,
          subDivision3: this.currentLocation.subDivision3,
        },
      })
    },
    editOhSubgroup() {
      this.$emit('editOhSubgroup', {
        data: this.ohSubgroups,
      })
    },
    saveOhSubgroup() {
      const subgroup = this.ohSubgroups[this.$refs.sliderOhSubgroup.swiper.activeIndex]
      this.$emit('saveOhSubgroup', {
        objKey: {
          dateFrom: '',
          dateTo: '',
          hours: subgroup.hours,
          label: subgroup.label,
          locationId: this.currentLocation.id,
          openingHoursSubGroupId: subgroup.id,
          position: 1,
        },
        status: 'create',
        ohSubgroupId: subgroup.id,
      })
    },
    date(date) {
      return formatedDate(date, 'L', this.$i18n.locale)
    },
    mapCountry(countryCode) {
      const country = this.countries.find(country => country.countryCode === countryCode)
      return country ? country.name : countryCode
    },
    mapSubDivision(division, level) {
      let subDivision = null
      if (level === 'level1') {
        subDivision = this.subDivisions[level]?.find(sub => sub.code === division)
      } else if (level === 'level2') {
        subDivision = this.subDivisions[level]?.find(
          sub => sub.code === division && sub.code_up === this.currentLocation.subDivision1
        )
      } else if (level === 'level3') {
        subDivision = this.subDivisions[level]?.find(
          sub => sub.code === division && sub.code_up === this.currentLocation.subDivision2
        )
      }
      return subDivision ? subDivision.name : this.$t('errors.subDivisions')
    },
    sortExceptionalOpeningHour(period) {
      this.$emit('sortExceptionalOpeningHour', period.id)
    },
    updateLocationStatus(value) {
      const status = value ? '1' : '0'
      this.$emit('updateLocationStatus', status)
    },
    updateLocationGroupStatus(value) {
      const status = value ? '0' : '1'
      this.$emit('updateLocationGroupStatus', status)
    },
    sliderInitialized() {
      this.ohSubgroups.forEach((subgroups, i) => {
        if (subgroups.id === this.currentLocation.openingHoursSubGroupId) {
          this.$refs.sliderOhSubgroup.swiper.activeIndex = i
        }
      })
    },
    convertTime(time) {
      const amPm = !!(this.currentClient.ampmFormat * 1)
      if (amPm) {
        return convert24hto12h(time)
      }
      return time
    },
  },
}
</script>

<style lang="scss" scoped>
.location-general {
  &__status {
    &__date {
      display: flex;
      flex-direction: column;
      grid-gap: 4px;
      margin-top: calc(#{$gutter-mobile} / 2);
      font-size: $font-size-sm;
    }

    &__icon {
      margin-right: $gutter-mobile;
      border: 1px solid;
      border-radius: 50%;
      padding: 5px;
      width: 36px;
      height: 36px;

      .location-general__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-general__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }
    }

    &__state {
      margin-top: 2px;

      .location-general__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-general__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }
    }

    &__toggle {
      margin-left: $gutter-mobile;
    }
  }

  &__coordinates {
    &__section {
      display: flex;
      justify-content: space-between;

      &__label {
        font-weight: 500;
      }
    }
  }

  &__opening-hours-information {
    @include text-ellipsis(3);

    margin: 0;
    font-family: inherit;
  }

  &__eoh-group-notification {
    padding: 8px $gutter-mobile !important;
  }

  &__oh-group-notification,
  &__eoh-group-notification {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    transition: all $transition-duration-fast $transition-effect-default;
    margin-top: 4px;
    padding: 4px 0;
    line-height: 1.1;
    color: $orange-tree-poppy;
    font-size: 0.8rem;
    font-weight: 300;

    &__icon {
      margin-left: 4px;
    }

    &:hover {
      background-color: rgba($orange-tree-poppy, 0.1);
    }
  }

  &__hours {
    &--title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: ($gutter-mobile/2) ($gutter-mobile/2) 0 ($gutter-mobile/2);

      @media (min-width: $screen-sm) {
        padding: ($gutter-tablet/2) ($gutter-tablet/2) 0 ($gutter-tablet/2);
      }

      @media (min-width: $screen-xl) {
        padding: ($gutter-desktop/2) ($gutter-desktop/2) 0 ($gutter-desktop/2);
      }

      &__label {
        @include sub-title;

        margin: 0;
      }

      &__icon {
        color: map-get($generic-color-variants, 'success');
      }
    }

    &__slot {
      display: flex;
      justify-content: space-between;
      margin-bottom: $gutter-mobile / 2;

      &:last-child {
        margin-bottom: 0;
      }

      &__day {
        font-weight: 500;
      }

      &__time {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  &__dropdown {
    max-width: 160px;
  }
}
</style>
