<template>
  <modal
    name="modal-external-urls"
    :title="title"
    :has-apply="true"
    :is-updating="isUpdating"
    :has-delete="!isNew"
    :delete-label="$t('modal.externalUrls.delete.label')"
    :delete-tooltip="$t('modal.externalUrls.delete.tooltip')"
    @save="save"
    @remove="remove"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-dropdown
        class="required-asterisk modal-external-modules__input"
        v-model="modelData.source"
        :options="URL_SOURCES"
        :placeholder="$t('modal.externalUrls.label.module.placeholder')"
        :dropdown-label="$t('modal.externalUrls.label.module.dropdown')"
        :show-label="true"
      />
      <ui-input
        class="required-asterisk modal-external-modules__input"
        v-model="modelData.sourceUrl"
        :label="$t('modal.externalModules.label.value')"
        id="modal-module-value"
        :error="$v.modelData.sourceUrl.$error"
      >
        <template v-if="$v.modelData.sourceUrl.$error && !$v.modelData.sourceUrl.required">
          {{ $t('errors.required') }}
        </template>
        <template v-if="$v.modelData.sourceUrl.$error && !$v.modelData.sourceUrl.url">
          {{ $t('errors.url') }}
        </template>
      </ui-input>
    </template>
  </modal>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiInput from '@/components/UI/Input.vue'

const URL_SOURCES = [
  'instagram',
  'twitter',
  'youtube',
  'linkedin',
  'airbnb',
  'booking',
  'facebook',
  'hotels',
  'opentable',
  'tripadvisor',
  'trustpilot',
  'yelp',
  'gmb_website',
  'gmb_search',
  'gmb_maps',
  'gmb_add_review',
  'gmb_reviews',
  'gmb_qa',
  'gmb_addqa',
]

export default {
  name: 'ModalExternalModules',
  components: {
    UiDropdown,
    UiInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {},
    locationUrls: {
      type: Array,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: {},
    }
  },
  created() {
    this.URL_SOURCES = URL_SOURCES
  },
  mounted() {
    this.modelData = { ...this.data }
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      if (this.isNew) {
        this.$emit('save', this.modelData)
      } else {
        const currentLocationUrl = this.locationUrls.find(locationUrl => this.modelData.source === locationUrl.source)
        this.$emit('save', { ...currentLocationUrl, ...this.modelData })
      }
      this.$v.$reset()
    },
    remove() {
      const currentLocationUrl = this.locationUrls.find(locationUrl => this.modelData.source === locationUrl.source)
      this.$emit('remove', currentLocationUrl)
    },
    closed() {
      this.$emit('closed')
    },
  },
  computed: {
    isNew() {
      return !this.locationUrls.some(locationUrl => this.modelData.source === locationUrl.source)
    },
  },
  validations() {
    return {
      modelData: {
        source: {
          required,
        },
        sourceUrl: {
          required,
          url,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-external-modules {
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
