var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-dropdown"},[(_vm.showLabel)?_c('div',{staticClass:"ui-dropdown__label ui-dropdown__label--full"},[_vm._v(" "+_vm._s(_vm.decoratedDropdownLabel)+" ")]):_vm._e(),_c('multiselect',{staticClass:"ui-dropdown__multiselect",class:{
      'ui-dropdown__multiselect--no-absolute': _vm.noAbsolute,
      'ui-dropdown__multiselect--no-radius': _vm.noRadius,
      'ui-dropdown__multiselect--no-caret': !_vm.showCaret,
      'ui-dropdown__multiselect--error': _vm.error,
    },attrs:{"value":_vm.value,"options":_vm.filteredOptions,"placeholder":_vm.decoratedPlaceholder,"label":_vm.label ? _vm.label : null,"track-by":_vm.trackBy ? _vm.trackBy : null,"select-label":"","selected-label":"","deselect-label":"","allow-empty":false,"disabled":_vm.disabled,"max-height":_vm.maxHeight,"open-direction":_vm.openDirection,"internal-search":false},on:{"input":_vm.onInput,"close":_vm.onClose,"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"caret",fn:function(ref){
    var toggle = ref.toggle;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCaret),expression:"showCaret"}],staticClass:"ui-dropdown__multiselect__caret caret backoffice-icons",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return toggle.apply(null, arguments)}}},[_vm._v(" chevron_down ")])]}},{key:"singleLabel",fn:function(ref){
    var option = ref.option;
return [_vm._t("label",null,{"label":option})]}},{key:"option",fn:function(ref){
    var option = ref.option;
return [_vm._t("option",null,{"option":option})]}}],null,true)},[_c('template',{slot:"noResult"},[_vm._v(" "+_vm._s(_vm.noResultsLabel || _vm.$t('ui.dropdown.noResult'))+" ")]),_c('template',{slot:"noOptions"},[_vm._v(" "+_vm._s(_vm.$t('ui.dropdown.noOptions'))+" ")])],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.helper || _vm.error)?_c('div',{staticClass:"ui-dropdown__helper",class:{ 'ui-dropdown__helper--error': _vm.error }},[_vm._t("helper",function(){return [_vm._v(_vm._s(_vm.helper))]})],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }