<template>
  <div
    class="screen-flux"
    :class="[
      { 'screen-flux--grid': gridFlux },
      `screen-flux--${ratio}`,
      {
        'screen-flux--is-editable': isEditable && !isDraggable,
      },
    ]"
    :style="style"
    @click="isEditable ? onClick() : false"
    :id="`parent_${id}`"
  >
    <div :id="`overlay_${id}`" v-if="isDragOverMe" class="screen-flux__drag-over-me"></div>
    <skeleton-line v-if="isLoading" :no-radius="true" height="100px" width="100%" max-width="initial" />
    <img
      :id="`picture_${id}`"
      :src="picture"
      :class="{ 'screen-flux__img--grid': gridFlux }"
      class="screen-flux__img"
      v-if="picture"
    />

    <div v-if="tags.length" class="screen-flux__tag">{{ tags[0] }}</div>

    <div :id="`content_${id}`" class="screen-flux__content" v-if="title && !pictureMode">
      <skeleton-line v-if="isLoading" height="18px" width="60%" class="screen-flux__title" />
      <div :id="`title_${id}`" class="screen-flux__content__title" v-else>
        {{ title }}
      </div>
      <skeleton-line
        v-if="isLoading"
        height="18px"
        width="calc(100% - 24px)"
        class="screen-flux__content__description"
        max-width="initial"
      />
      <div :id="`description_${id}`" class="screen-flux__content__description" v-if="description && !isLoading">
        {{ description }}
      </div>
      <div :id="`link_${id}`" class="screen-flux__content__link" v-if="link">
        <span :id="`link-icon_${id}`" class="screen-flux__content__link__icon backoffice-icons">
          {{ icon }}
        </span>
        <a :id="`link-balise_${id}`" class="screen-flux__content__link__url" :href="link" @click.stop target="_blank">
          {{ link }}
        </a>
      </div>
      <div :id="`date_${id}`" class="screen-flux__content__date" v-if="from || to">
        <span :id="`date-icon_${id}`" class="screen-flux__content__date__icon backoffice-icons"> calendar </span>
        <div :id="`date-from_${id}`" class="screen-flux__content__date__from" v-if="from">
          <span :id="`date-from-from_${id}`" v-if="from && to">
            {{ $t('common.date.from') }}
          </span>
          <span :id="`date-from-to_${id}`" v-else-if="from && !to">
            {{ $t('common.date.starting') }}
          </span>
          {{ date(from) }}
        </div>
        <div :id="`date-to_${id}`" class="screen-flux__content__date__to" v-if="to">
          <span :id="`date-to-to_${id}`" v-if="from && to">
            {{ $t('common.date.to') }}
          </span>
          <span :id="`date-to-from_${id}`" v-else-if="!from && to">
            {{ $t('common.date.ending') }}
          </span>
          {{ date(to) }}
        </div>
      </div>
    </div>

    <div :id="`content_picture_${id}`" class="screen-flux__content__picture" v-if="pictureMode">
      <skeleton-line v-if="isLoading" height="18px" width="60%" class="screen-flux__title" />
      <div :id="`content_picture_title_${id}`" class="screen-flux__content__picture__title" v-else>
        {{ title }}
      </div>
      <div :id="`content_picture_date_${id}`" class="screen-flux__content__picture__date">
        {{ $t('common.date.created', { date: timeFromNow(createTime, true, locale) }) }}
      </div>
      <div :id="`content_picture_size_${id}`" class="screen-flux__content__picture__size">
        {{ $t('common.date.size', { size }) }}
      </div>
      <div class="screen-flux__content__picture__block" :id="`content_picture_block_${id}`">
        <div :id="`content_picture_view_${id}`" class="screen-flux__content__picture__block__view">
          <span
            :id="`content_picture_view-icon_${id}`"
            class="screen-flux__content__picture__block__view__icon backoffice-icons"
          >
            visibility
          </span>
          {{ numberViews }}
        </div>
        <ui-button
          class="screen-flux__content__picture__block__button"
          :id="`content_picture_block_button_${id}`"
          variant="error"
          button="secondary"
          :label="$t('locationsGmb.id.photos.section.categories.button.left')"
          :icon="icon"
          :icon-only="true"
          @click.stop="iconClick"
          :is-link="true"
          :href="href"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { timeFromNow } from '@/utils/hours.util'
import { productColor } from '@/config/productColor.config'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import { formatedDate } from '@/utils/date.util'
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'ScreenFlux',
  components: {
    SkeletonLine,
    UiButton,
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    picture: {
      type: String,
      required: false,
      default: '',
    },
    link: {
      type: String,
      required: false,
      default: '',
    },
    gridFlux: {
      type: Boolean,
      required: false,
      default: false,
    },
    pictureMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
    ratio: {
      type: String,
      required: false,
      default: '',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    from: {
      type: String,
      required: false,
      default: '',
    },
    to: {
      type: String,
      required: false,
      default: '',
    },
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
    locale: {
      type: String,
      required: false,
      default: 'fr',
    },
    createTime: {
      type: String,
      required: false,
      default: '',
    },
    numberViews: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    isDraggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDragOverMe: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      timeFromNow,
      formatedDate,
    }
  },
  computed: {
    style() {
      return {
        '--product-color': productColor[this.productLine],
      }
    },
    productLine() {
      return this.$route.meta.productLine || 'none'
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
    iconClick() {
      this.$emit('iconClick')
    },
    date(date) {
      return formatedDate(date, 'L', this.locale)
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-flux {
  display: flex;
  position: relative;
  flex-direction: column;
  transition: $transition-duration-fast $transition-effect-default;
  transition-property: background-color, box-shadow;
  border-radius: $radius-default;
  box-shadow: 0 0 8px var(--box-shadow-color);
  width: 100%;
  min-height: 42px;
  overflow: hidden;

  &--grid {
    display: grid;
    grid-template-rows: minmax(auto, 250px) auto;
  }

  &--is-editable {
    &:hover {
      box-shadow: 0 0 16px var(--box-shadow-color);
      background-color: var(--bg-color-hover);
      cursor: pointer;
    }
  }

  &__drag-over-me {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($grey-french, 0.6);
  }

  &--1-1,
  &--1-2,
  &--1-3,
  &--2-3 {
    margin: $gutter-mobile / 2;

    @media (min-width: $screen-sm) {
      margin: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin: $gutter-desktop / 2;
    }
  }

  &--1-2 {
    @media (min-width: $screen-sm) {
      width: calc(100% / 2 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 2 - #{$gutter-desktop});
    }
  }

  &--1-3 {
    @media (min-width: $screen-sm) {
      width: calc(100% / 2 - #{$gutter-tablet});
    }

    @media (min-width: $screen-md) {
      width: calc(100% / 3 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 3 - #{$gutter-desktop});
    }
  }

  &--2-3 {
    @media (min-width: $screen-sm) {
      width: calc(100% * (2 / 3) - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% * (2 / 3) - #{$gutter-desktop});
    }
  }

  &__tag {
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: $radius-default;
    background-color: $blue-dodger;
    padding: 4px 8px;
    color: $white;
  }

  &__img {
    display: inline-flex;
    transition: filter $transition-duration-fast $transition-effect-default;
    width: 100%;

    &--grid {
      height: 100%;
    }

    .screen-flux--is-editable:hover & {
      filter: brightness(0.8);
    }
  }

  &__content {
    margin: 12px;

    &__picture {
      margin: 12px;

      &__title {
        font-size: $font-size-default;
      }

      &__date,
      &__size {
        margin-top: 2px;
        color: var(--text-color-alt);
        font-size: $font-size-sm;
      }

      &__block {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;

        &__view {
          display: flex;
          align-items: center;
          margin-top: 8px;
          color: var(--text-color-alt);
          font-size: 0.83rem;

          &__icon {
            margin-right: 4px;
            font-size: $font-size-md;
          }
        }

        &__button {
          margin-right: -12px;
        }
      }
    }

    &__title {
      font-weight: 600;
    }

    &__description {
      @include text-ellipsis(3, 14px);

      margin-top: 8px;
      color: var(--text-color-alt);
    }

    &__link,
    &__date {
      display: flex;
      align-items: center;
      margin-top: 8px;

      &__icon {
        margin-right: 8px;
        color: var(--product-color);
        font-size: $font-size-lg;
      }

      &__url {
        @include text-ellipsis(1, 12px);

        color: var(--text-color-alt);
        font-size: $font-size-sm;

        &:hover {
          color: var(--product-color);
        }
      }
    }

    &__date {
      &__from,
      &__to {
        color: var(--text-color-alt);
        font-size: $font-size-sm;
      }

      &__from {
        margin-right: 4px;
      }
    }
  }
}
</style>
