<template>
  <div>
    <modal
      :title="title"
      name="modal-media"
      :has-apply="true"
      :has-delete="canBeDeleted"
      :is-updating="isUpdating"
      :delete-label="canBeDeleted ? $t(`modal.media.${parameters.type}.delete.label`) : ''"
      :delete-tooltip="canBeDeleted ? $t(`modal.media.${parameters.type}.delete.tooltip`) : ''"
      @save="save"
      @remove="remove"
      @closed="closed"
    >
      <template v-slot:container>
        <div class="modal-media__picture">
          <div class="modal-media__picture__label modal-media__picture__label--full">
            {{ $t(`modal.media.${parameters.type}.label.picture`) }}
          </div>

          <ui-clipper-gmb
            v-if="parameters.category"
            :picture="modelData.picture"
            @clip="clip"
            @delete="deleteImg"
            :type="parameters.type"
            :category="parameters.category"
          />

          <ui-clipper v-else :picture="modelData.picture" @clip="clip" @delete="deleteImg" :type="parameters.type" />

          <div
            class="modal-media__picture__helper modal-media__picture__helper--error"
            v-if="$v.image.$error && !$v.image.required"
          >
            {{ $t('errors.picture') }}
          </div>
        </div>

        <ui-input
          class="modal-media__input"
          v-model="modelData.title"
          :label="$t(`modal.media.${parameters.type}.label.title`)"
          v-if="parameters.type !== 'logo' && parameters.type !== 'locationPictures' && parameters.type !== 'favicon'"
          id="modal-media-title"
          :error="$v.modelData.title.$error"
          @enter="save"
        >
          <template v-if="$v.modelData.title.$error && !$v.modelData.title.required">
            {{ $t('errors.required') }}
          </template>
        </ui-input>

        <ui-input
          class="modal-media__input"
          v-model="modelData.alt"
          :label="$t(`modal.media.${parameters.type}.label.alt`)"
          id="modal-media-alt"
        />

        <ui-input
          class="modal-media__input"
          v-model="modelData.titleSeo"
          :label="$t(`modal.media.${parameters.type}.label.titleSeo`)"
          v-if="parameters.type !== 'logo' && parameters.type !== 'locationPictures' && parameters.type !== 'favicon'"
          id="modal-media-titleSeo"
          @enter="save"
        />

        <ui-input
          class="modal-media__input"
          v-model="modelData.link"
          :label="$t(`modal.media.${parameters.type}.label.link`)"
          id="modal-media-link"
          :error="$v.modelData.link.$error"
          v-if="parameters.type !== 'favicon' && parameters.type !== 'locationPictures' && !parameters.noLink"
          @enter="save"
        >
          <template v-if="$v.modelData.link.$error && !$v.modelData.link.required">
            {{ $t('errors.required') }}
          </template>
          <template v-if="$v.modelData.link.$error && !$v.modelData.link.url">
            {{ $t('errors.url') }}
          </template>
        </ui-input>

        <ui-input
          class="modal-media__input"
          v-if="parameters.type === 'event' || parameters.type === 'team' || parameters.type === 'dynamicContent'"
          v-model.trim="modelData.linkTitle"
          :label="$t(`modal.media.linkTitle.label`)"
          id="modal-media-link-title"
        />

        <ui-textarea
          class="modal-media__input"
          v-if="parameters.type === 'event' || parameters.type === 'team' || parameters.type === 'dynamicContent'"
          v-model.trim="modelData.description"
          :label="$t(`modal.media.${parameters.type}.label.description`)"
          id="modal-media-description"
        />

        <div class="modal-media__dates" v-if="parameters.type === 'event' || parameters.type === 'dynamicContent'">
          <div class="modal-media__dates__block">
            <ui-date-picker
              class="modal-media__dates__block__input"
              v-model="modelData.from"
              :locale="$i18n.locale"
              :max-date="modelData.to"
              id="modal-media-from"
              :label="$t(`modal.media.${parameters.type}.label.dateFrom`)"
            />

            <ui-button
              class="modal-media__dates__block__remove"
              v-if="modelData.from"
              button="secondary"
              icon="close"
              :icon-only="true"
              :label="$t('modal.media.button.removeDateFrom')"
              v-tooltip="{
                placement: 'top',
                trigger: 'hover',
                content: $t('modal.media.button.removeDateFrom'),
                offset: 3,
              }"
              @click="clearDate('from')"
            />
          </div>

          <div class="modal-media__dates__block">
            <ui-date-picker
              class="modal-media__dates__block__input"
              v-model="modelData.to"
              :locale="$i18n.locale"
              :min-date="modelData.from"
              id="modal-media-to"
              :label="$t(`modal.media.${parameters.type}.label.dateTo`)"
            />

            <ui-button
              class="modal-media__dates__block__remove"
              v-if="modelData.to"
              button="secondary"
              icon="close"
              :icon-only="true"
              :label="$t('modal.media.button.removeDateTo')"
              v-tooltip="{
                placement: 'top',
                trigger: 'hover',
                content: $t('modal.media.button.removeDateTo'),
                offset: 3,
              }"
              @click="clearDate('to')"
            />
          </div>
        </div>

        <div class="modal-media__dates" v-if="parameters.type === 'event' || parameters.type === 'dynamicContent'">
          <div class="modal-media__dates__block">
            <ui-date-picker
              class="modal-media__dates__block__input"
              v-model="modelData.publication_from"
              :locale="$i18n.locale"
              :max-date="modelData.publication_to"
              id="modal-media-publication-from"
              :label="$t(`modal.media.${parameters.type}.label.publicationFrom`)"
            />

            <ui-button
              class="modal-media__dates__block__remove"
              v-if="modelData.publication_from"
              button="secondary"
              icon="close"
              :icon-only="true"
              :label="$t('modal.media.button.removeDateFrom')"
              v-tooltip="{
                placement: 'top',
                trigger: 'hover',
                content: $t('modal.media.button.removeDateFrom'),
                offset: 3,
              }"
              @click="clearDate('publication_from')"
            />
          </div>

          <div class="modal-media__dates__block">
            <ui-date-picker
              class="modal-media__dates__block__input"
              v-model="modelData.publication_to"
              :locale="$i18n.locale"
              :min-date="modelData.publication_from"
              id="modal-media-publication-to"
              :label="$t(`modal.media.${parameters.type}.label.publicationTo`)"
            />

            <ui-button
              class="modal-media__dates__block__remove"
              v-if="modelData.publication_to"
              button="secondary"
              icon="close"
              :icon-only="true"
              :label="$t('modal.media.button.removeDateTo')"
              v-tooltip="{
                placement: 'top',
                trigger: 'hover',
                content: $t('modal.media.button.removeDateTo'),
                offset: 3,
              }"
              @click="clearDate('publication_to')"
            />
          </div>
        </div>

        <div class="modal-media__tags" v-if="parameters.type === 'event' || parameters.type === 'dynamicContent'">
          <ui-tag
            class="modal-media__tags__select"
            v-model="modelData.tags"
            :placeholder="$t('modal.media.event.label.tags')"
            :error="$v.modelData.tags.$error"
            @tag="addTag"
          >
            <template v-slot:helper v-if="$v.modelData.tags.$error">
              {{ $t('errors.tag') }}
            </template>
          </ui-tag>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UiInput from '@/components/UI/Input.vue'
import UiTag from '@/components/UI/Tag.vue'
import UiTextarea from '@/components/UI/Textarea.vue'
import UiButton from '@/components/UI/Button.vue'
import UiDatePicker from '@/components/UI/DatePicker.vue'
import UiClipper from '@/components/UI/Clipper.vue'
import UiClipperGmb from '@/components/UI/ClipperGmb.vue'
import { requiredIf, url } from 'vuelidate/lib/validators'
import { tag } from '@/validators/tag.validator'

export default {
  name: 'ModalMedia',
  components: {
    UiInput,
    UiTag,
    UiTextarea,
    UiButton,
    UiDatePicker,
    UiClipper,
    UiClipperGmb,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({
        picture: '',
        titleSeo: '',
        title: '',
        alt: '',
        linkTitle: '',
        description: '',
        link: '',
        from: '',
        to: '',
        publication_from: '',
        publication_to: '',
        tags: [],
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        type: 'event',
        noLink: false,
      }),
    },
  },
  data() {
    return {
      modelData: null,
      image: '',
      canBeDeleted: false,
    }
  },
  watch: {
    'modelData.title': {
      handler() {
        if (!this.isUpdateMedia) {
          this.modelData.alt = `${this.modelData.title} ${this.currentClient.name}`
        }
      },
    },
  },
  mounted() {
    this.modelData = Object.assign({}, this.data, {
      ...this.data,
      from: this.data && this.data.from ? new Date(this.data.from) : '',
      to: this.data && this.data.to ? new Date(this.data.to) : '',
      publication_from: this.data && this.data.publication_from ? new Date(this.data.publication_from) : '',
      publication_to: this.data && this.data.publication_to ? new Date(this.data.publication_to) : '',
      tags: this.data && this.data.tags ? this.data.tags : [],
    })
    if (this.modelData && this.modelData.id && this.modelData.id !== -1) {
      this.canBeDeleted = true
    }
  },
  computed: {
    ...mapState({
      currentClient: state => state.client.currentClient,
    }),
    isUpdateMedia() {
      return !!this.modelData.id
    },
  },
  methods: {
    addTag(tag) {
      this.modelData.tags = [...this.modelData.tags, tag]
    },
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.isUpdateMedia) {
          this.$emit('save', {
            media: this.modelData,
            type: this.parameters.type,
            image: this.image,
          })
        } else {
          this.$emit('add', {
            media: this.modelData,
            type: this.parameters.type,
            image: this.image,
          })
        }
        this.$v.$reset()
      }
    },
    remove() {
      this.$emit('remove', {
        media: this.modelData,
        type: this.parameters.type,
      })
    },
    closed() {
      this.$emit('closed')
    },
    clearDate(date) {
      this.modelData[date] = ''
    },
    clip(image) {
      this.modelData.picture = ''
      this.image = image
    },
    deleteImg() {
      this.modelData.picture = ''
      this.image = ''
    },
  },
  validations() {
    return {
      image: {
        required: requiredIf(() => {
          return (
            (this.parameters.type === 'locationPictures' && !this.modelData.picture) ||
            (this.parameters.type === 'hotPictures' && !this.modelData.title && !this.modelData.picture)
          )
        }),
      },
      modelData: {
        picture: {
          required: requiredIf(() => {
            return this.parameters.type === 'locationPictures' && !this.image
          }),
        },
        title: {
          required: requiredIf(() => {
            return (
              this.parameters.type !== 'logo' &&
              this.parameters.type !== 'favicon' &&
              this.parameters.type !== 'locationPictures' &&
              this.parameters.type !== 'hotPictures'
            )
          }),
        },
        link: {
          required: requiredIf(() => {
            return this.parameters.type === 'logo' && !this.parameters.noLink
          }),
          url,
        },
        tags: {
          $each: {
            tag,
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-media {
  &__picture {
    position: relative;
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &__label {
      @include input-label;
    }

    &__helper {
      @include input-helper;
    }
  }

  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &--successive {
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__dates {
    margin: 8px 0;

    @media (min-width: $screen-sm) {
      display: flex;
      justify-content: space-between;
    }

    &__block {
      position: relative;
      margin-bottom: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-bottom: 0;
        width: calc(50% - #{$gutter-tablet} / 2);
      }

      &__remove {
        position: absolute;
        right: 1px;
        bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__tags {
    margin: 8px 0;
  }
}
</style>
