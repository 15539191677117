<template>
  <div class="modal-hours-day__wrapper">
    <div class="modal-hours-day__wrapper__block">
      <div class="modal-hours-day__wrapper__block__day-mobile">{{ $t(`common.days.${dayKey}`).slice(0, 3) }}.</div>
      <div class="modal-hours-day__wrapper__block__day">
        {{ $t(`common.days.${dayKey}`) }}
      </div>
      <ui-switch
        v-if="!hisCustomHours"
        class="modal-hours-day__wrapper__block__status"
        :id="`switch-${dayKey}`"
        v-model="day.open"
        @input="toggleSlots(day)"
      >
        <template v-if="day.open">
          {{ $t('common.label.open') }}
        </template>
        <template v-else>
          {{ $t('common.label.closed') }}
        </template>
      </ui-switch>
      <ui-checkbox
        v-if="!hisCustomHours && day.open"
        class="modal-hours-day__wrapper__block__h24"
        :id="`checkbox-h24-${dayKey}`"
        v-model="day.h24"
        @input="toggleSlots(day)"
      >
        {{ $t('common.label.h24') }}
      </ui-checkbox>
      <div class="modal-hours-day__wrapper__block__duplicate modal-hours-day__wrapper__duplicate">
        <v-menu
          v-model="duplicateMenu"
          left
          :close-on-content-click="false"
          max-width="300"
          transition="slide-y-reverse-transition"
          @input="stopDuplicating"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-btn
              @click="duplicate(day)"
              :icon="$vuetify.breakpoint.xsOnly"
              color="primary"
              text
              rounded
              large
              v-bind="attrs"
              v-on="{ ...menu }"
            >
              <v-icon :class="{ 'tw-mr-2': !$vuetify.breakpoint.xsOnly }"> {{ icons.mdiContentCopy }} </v-icon>
              <span v-if="!$vuetify.breakpoint.xsOnly">
                {{ $t('modal.hours.button.duplicate') }}
              </span>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="(day, idxDay) in daysArray" :key="`checkbox-day-${idxDay}`">
              <v-checkbox
                class="tw-mt-0 tw-pt-0 modal-opening-hours__grid__wrapper__block__duplicate__checkbox"
                :input-value="duplicateArray"
                hide-details=""
                :value="day.key"
                :label="$t(`common.days.${day.key}`)"
                :disabled="duplicatingDay && day.key === duplicatingDay.key"
                @change="setDuplicateArray"
              ></v-checkbox>
            </v-list-item>
            <v-btn color="success" text @click="endDuplicating">
              {{ $t('modal.hours.button.endDuplicating') }}
            </v-btn>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div v-if="hisCustomHours" class="modal-hours-day__wrapper__slots__custom">
      <ui-button
        v-if="dayEmptySlots"
        class="modal-hours-day__wrapper__slots__custom__add"
        :label="$t('modal.hours.button.addCustom')"
        button="secondary"
        variant="success"
        @click="addCustomSlot(day)"
      />
      <div
        class="modal-hours-day__wrapper__slots__custom__line"
        v-for="(slot, idxSlot) in day.slots"
        :key="`slot-${dayKey}-${idxSlot}`"
      >
        <div class="modal-hours-day__wrapper__slots__custom__line__string">
          <ui-input
            class="modal-hours-day__wrapper__slots__custom__line__string__input"
            v-model="slot.opening"
            :label="$t(`modal.hours.input.schedule.${idxSlot}`)"
            :id="`modal-hour-schedule-${dayIdx}-${idxSlot}`"
          />
        </div>
      </div>
      <div class="modal-hours-day__wrapper__slots__custom__delete">
        <ui-button
          v-if="!dayEmptySlots"
          :label="$t('modal.hours.button.deleteSlots')"
          button="secondary"
          variant="error"
          icon="delete"
          :icon-only="true"
          @click="deleteCustomSlot(day)"
          v-tooltip="{
            container: '.modal__wrapper__box',
            placement: 'top',
            trigger: 'hover',
            content: $t('modal.hours.button.deleteSlots'),
            offset: 3,
            classes: 'modal-hours-day',
          }"
        />
      </div>
    </div>

    <div class="modal-hours-day__wrapper__slots" v-else-if="day.open && !day.h24">
      <div
        class="modal-hours-day__wrapper__slots__line"
        v-for="(slot, idxSlot) in day.slots"
        :key="`slot-${dayKey}-${idxSlot}`"
      >
        <div class="modal-hours-day__wrapper__slots__line__dropdown">
          <ui-dropdown
            class="modal-hours-day__wrapper__slots__line__dropdown__input"
            :id="`dropdown-opening-${dayKey}-${dayIdx}-${idxSlot}`"
            :key="`dropdown-opening-${dayKey}-${dayIdx}-${idxSlot}`"
            :value="slot.opening"
            :options="hoursOptions"
            :show-caret="false"
            track-by="id"
            label="name"
            :placeholder="$t('common.label.opening')"
            :error="validations.daysArray.$each[dayIdx].slots.$each[idxSlot].opening.$error"
            :no-results-label="$t('modal.hours.dropdown.noResult')"
            @input="updateSlotHours($event, idxSlot, true)"
          >
            <template
              v-slot:helper
              v-if="
                validations.daysArray.$each[dayIdx].slots.$each[idxSlot].opening.$error &&
                !validations.daysArray.$each[dayIdx].slots.$each[idxSlot].opening.required
              "
            >
              {{ $t('errors.required') }}
            </template>
          </ui-dropdown>
          <ui-dropdown
            class="modal-hours-day__wrapper__slots__line__dropdown__input"
            :id="`dropdown-closing-${dayKey}-${dayIdx}-${idxSlot}`"
            :key="`dropdown-closing-${dayKey}-${dayIdx}-${idxSlot}`"
            :value="slot.closing"
            :options="hoursOptions"
            :show-caret="false"
            track-by="id"
            label="name"
            :placeholder="$t('common.label.closing')"
            :error="validations.daysArray.$each[dayIdx].slots.$each[idxSlot].closing.$error"
            :no-results-label="$t('modal.hours.dropdown.noResult')"
            @input="updateSlotHours($event, idxSlot, false)"
          >
            <template
              v-slot:helper
              v-if="
                validations.daysArray.$each[dayIdx].slots.$each[idxSlot].closing.$error &&
                !validations.daysArray.$each[dayIdx].slots.$each[idxSlot].closing.required
              "
            >
              {{ $t('errors.required') }}
            </template>
          </ui-dropdown>
        </div>
        <ui-button
          class="modal-hours-day__wrapper__slots__line__cta"
          :label="$t('modal.hours.button.addSlot')"
          button="secondary"
          icon="add_hours"
          :iconOnly="true"
          v-if="idxSlot === 0"
          :disabled="
            !day.slots[day.slots.length - 1].opening ||
            !day.slots[day.slots.length - 1].closing ||
            day.slots.length === 2
          "
          v-tooltip="{
            container: '.modal__wrapper__box',
            placement: 'top',
            trigger: 'hover',
            content: $t('modal.hours.button.addSlot'),
            offset: 3,
          }"
          @click="addSlot(day)"
        />
        <ui-button
          class="modal-hours-day__wrapper__slots__line__cta"
          :label="$t('modal.hours.button.removeSlot')"
          button="secondary"
          icon="close"
          :iconOnly="true"
          v-if="idxSlot > 0"
          v-tooltip="{
            container: '.modal__wrapper__box',
            placement: 'top',
            trigger: 'hover',
            content: $t('modal.hours.button.removeSlot'),
            offset: 3,
          }"
          @click="deleteSlot(day, idxSlot)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mdiClipboardTextClock, mdiContentCopy } from '@mdi/js'
import { timeArray } from '@/utils/hours.util'
import UiSwitch from '@/components/UI/Switch.vue'
import UiCheckbox from '@/components/UI/Checkbox.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiInput from '@/components/UI/Input.vue'
import UiButton from '@/components/UI/Button.vue'

export default {
  props: {
    hoursFormat: {
      type: Boolean,
      required: false,
      default: false,
    },
    hisCustomHours: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentClient: {
      type: Object,
      required: false,
      default: () => {},
    },
    daysArray: {
      type: Array,
      required: true,
    },
    duplicateArray: {
      type: Array,
      required: true,
    },
    day: {
      type: Object,
      required: true,
    },
    dayKey: {
      type: String,
      required: true,
    },
    dayIdx: {
      type: Number,
      required: true,
    },
    duplicatingDay: {
      type: [Object, null],
      required: false,
      default: null,
    },
    validations: {
      type: Object,
      required: true,
    },
  },
  components: {
    UiButton,
    UiSwitch,
    UiCheckbox,
    UiInput,
    UiDropdown,
  },
  data() {
    return {
      inputValue: null,
      icons: {
        mdiClipboardTextClock,
        mdiContentCopy,
      },
      duplicateMenu: false,
    }
  },
  computed: {
    hoursOptions() {
      const isAmpm = this.clientHasAmpmFormat ? true : false
      return timeArray(5, 0, isAmpm)
    },
    clientHasAmpmFormat() {
      return this.hoursFormat && this.currentClient?.ampmFormat === '1'
    },
    dayEmptySlots() {
      return this.day.slots.length < 1
    },
  },
  methods: {
    updateSlotHours(value, idxSlot, isOpening) {
      this.$emit('updateSlotHours', { dayIdx: this.dayIdx, value, idxSlot, isOpening })
    },
    cleanSlot(idxSlot) {
      this.day.slots[idxSlot] = { opening: '', closing: '' }
    },
    toggleSlots(day) {
      this.$emit('toggleSlots', day)
    },
    stopDuplicating() {
      this.$emit('stopDuplicating')
    },
    duplicate(day) {
      this.$emit('duplicate', day)
    },
    endDuplicating() {
      this.duplicateMenu = false
      this.$emit('endDuplicating')
    },
    addSlot(day) {
      this.$emit('addSlot', day)
    },
    deleteSlot(day, idxSlot) {
      this.$emit('deleteSlot', { day, index: idxSlot })
    },
    setDuplicateArray(dayArray) {
      this.$emit('setDuplicateArray', dayArray)
    },
    addCustomSlot(day) {
      this.$emit('addCustomSlot', day)
    },
    deleteCustomSlot(day) {
      this.$emit('deleteCustomSlot', day)
    },
  },
}
</script>

<style lang="scss">
.modal-hours-day {
  .tooltip-inner {
    text-align: center;
  }
}
</style>

<style scoped lang="scss">
.modal-hours-day {
  &__wrapper {
    position: relative;
    flex-wrap: wrap;
    grid-gap: 8px;
    padding: $gutter-mobile $gutter-mobile / 2;

    @media (min-width: $screen-sm) {
      padding: $gutter-mobile $gutter-tablet / 2;
    }

    @media (min-width: $screen-md) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__block {
      display: grid;
      flex: 1 0 100%;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: $gutter-mobile;
      align-items: center;

      @media (min-width: $screen-md) {
        grid-template-columns: repeat(4, 1fr);
        padding-top: 13px;
        width: 40%;
      }

      &__day-mobile {
        @media (min-width: $screen-sm) {
          display: none;
        }
      }

      &__day {
        display: none;

        @media (min-width: $screen-sm) {
          display: block;
        }
      }

      &__day,
      &__day-mobile {
        grid-column: 1;
        font-weight: 600;
      }

      &__status {
        grid-column: 2;

        @media (min-width: $screen-sm) {
          justify-self: center;
        }
      }

      &__h24 {
        grid-column: 3;

        @media (min-width: $screen-sm) {
          justify-self: center;
        }
      }

      &__duplicate {
        display: block;
        position: relative;
        grid-column: 4;
        justify-self: flex-end;

        @media (min-width: $screen-md) {
          display: none;
        }
      }
    }

    &__slots {
      @media (min-width: $screen-md) {
        width: 100%;
      }

      &__custom {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        @media (min-width: $screen-md) {
          flex-wrap: nowrap;
        }

        &__line {
          flex: 1 1 100%;
          margin-top: $gutter-mobile * 1.5;

          @media (min-width: $screen-md) {
            flex-wrap: nowrap;
            margin-top: 0;
          }

          &:first-child {
            @media (min-width: $screen-md) {
              margin-right: 8px;
            }
          }

          &__string {
            flex: 100%;
            margin: 4px 0;

            &__input {
              padding-top: 0;
            }
          }
        }

        &__delete {
          margin: 0 auto;
          margin-left: 4px;

          @media (min-width: $screen-md) {
            margin: 0 0;
          }
        }
      }

      &__line {
        display: flex;
        margin-top: $gutter-mobile * 1.5;

        @media (min-width: $screen-sm) {
          margin-top: $gutter-tablet / 1.2;
        }

        &:first-child {
          @media (min-width: $screen-md) {
            margin-top: 0;
          }
        }

        &__dropdown {
          display: flex;
          flex: 100%;

          &__input {
            margin-right: $gutter-mobile / 3;

            @media (min-width: $screen-sm) {
              margin-right: $gutter-tablet / 2;
            }
          }
        }

        &__checkbox {
          margin-top: 11px;
          margin-right: $gutter-mobile / 2;

          @media (min-width: $screen-sm) {
            margin-right: $gutter-mobile;
          }

          &__icon {
            display: flex;
          }
        }

        &__popover {
          padding: $gutter-mobile;
          min-width: 350px;

          &__choice {
            padding-top: $gutter-mobile;
            text-align: center;
          }
        }
      }
    }

    &__duplicate {
      @media (min-width: $screen-md) {
        display: block;
        position: relative;
        align-self: center;
        justify-self: flex-end;
        margin-top: $gutter-tablet / 2;
        margin-top: 0;
      }

      &:hover {
        cursor: pointer;
      }

      &__icon {
        margin-right: 12px;
        color: map-get($generic-color-variants, 'gtr');
        font-size: 20px;
      }

      &__label {
        color: map-get($generic-color-variants, 'gtr');
        font-weight: 500;
      }
    }
  }
}
</style>

<style lang="scss">
.modal-hours-day__wrapper__duplicate {
  &__popover {
    max-width: 220px;
    overflow: hidden;
    &__cta {
      border-radius: 0;
      width: 100%;
      font-size: $font-size-default;
    }
    &__day {
      width: 100%;
      font-size: $font-size-default;
      &--not-disabled {
        &:hover {
          background-color: var(--bg-color-hover);
        }
      }
      .ui-checkbox {
        &__label {
          padding: 0 $gutter-mobile;
          min-height: 48px;
        }
      }
    }
  }
}
</style>
