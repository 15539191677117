<template>
  <modal
    class="modal-exceptional-hours"
    :title="title"
    name="modal-exceptional-hours"
    :has-apply="true"
    :has-delete="canBeDeleted"
    :is-updating="isUpdating"
    :width="900"
    :delete-label="$t('modal.exceptionalHours.delete.label')"
    :delete-tooltip="$t('modal.exceptionalHours.delete.tooltip')"
    @save="save"
    @remove="remove"
    @closed="closed"
  >
    <template v-slot:container>
      <div class="modal-exceptional-hours__content">
        <div class="modal-exceptional-hours__content__grid">
          <div class="modal-exceptional-hours__content__grid__wrapper">
            <div class="modal-exceptional-hours__content__grid__wrapper__section" v-if="parameters.type !== 'gmb'">
              <ui-input
                class="modal-exceptional-hours__content__grid__wrapper__section__input"
                v-model="hoursFormatted.label"
                :label="$t('modal.exceptionalHours.label.title')"
                id="modal-exceptional-hours-label"
                :error="$v.hoursFormatted.label.$error"
              >
                <template v-if="$v.hoursFormatted.label.$error && !$v.hoursFormatted.label.required">
                  {{ $t('errors.required') }}
                </template>
              </ui-input>
              <ui-checkbox
                class="modal-exceptional-hours__content__grid__wrapper__section__range"
                :id="`checkbox-range`"
                v-model="hoursFormatted.isRange"
              >
                <span class="backoffice-icons">date_range</span>
              </ui-checkbox>
            </div>
            <div class="modal-exceptional-hours__content__grid__wrapper__block">
              <ui-checkbox
                class="modal-exceptional-hours__content__grid__wrapper__block__range"
                :id="`checkbox-range`"
                v-model="hoursFormatted.isRange"
                v-if="parameters.type !== 'gmb'"
              >
                <span class="backoffice-icons">date_range</span>
              </ui-checkbox>

              <ui-date-picker
                class="modal-exceptional-hours__content__grid__wrapper__block__date"
                v-model="hoursFormatted.date"
                :locale="$i18n.locale"
                :mode="hoursFormatted.isRange ? 'range' : ''"
                id="input-date"
                design="minimalist"
                :label="
                  hoursFormatted.isRange
                    ? [
                        $t('modal.exceptionalHours.label.dateRange.start'),
                        $t('modal.exceptionalHours.label.dateRange.end'),
                      ]
                    : $t('modal.exceptionalHours.label.date')
                "
                :error="$v.hoursFormatted.date.$error"
              >
                <template v-if="$v.hoursFormatted.date.$error && !$v.hoursFormatted.date.required">
                  {{ $t('errors.required') }}
                </template>
              </ui-date-picker>
              <ui-switch
                class="modal-exceptional-hours__content__grid__wrapper__block__status"
                :id="`switch-status`"
                v-model="hoursFormatted.open"
                @input="toggleSlots()"
              >
                <template v-if="hoursFormatted.open">
                  {{ $t('common.label.open') }}
                </template>
                <template v-else>
                  {{ $t('common.label.closed') }}
                </template>
              </ui-switch>
            </div>
            <div class="modal-exceptional-hours__content__grid__wrapper__slots" v-if="hoursFormatted.open">
              <div
                class="modal-exceptional-hours__content__grid__wrapper__slots__line"
                v-for="(slot, idxSlot) in hoursFormatted.slots"
                :key="`slot-${idxSlot}`"
              >
                <ui-dropdown
                  class="required-asterisk modal-exceptional-hours__content__grid__wrapper__slots__line__input"
                  :id="`dropdown-opening-${idxSlot}`"
                  :key="`dropdown-opening-${idxSlot}`"
                  v-model="slot.opening"
                  :options="hoursOptions"
                  :show-caret="false"
                  :noAbsolute="true"
                  track-by="id"
                  label="name"
                  :placeholder="$t('common.label.opening')"
                  :error="$v.hoursFormatted.slots.$each[idxSlot].opening.$error"
                  :no-results-label="$t('modal.exceptionalHours.dropdown.noResult')"
                >
                  <template
                    v-slot:helper
                    v-if="
                      $v.hoursFormatted.slots.$each[idxSlot].opening.$error &&
                      !$v.hoursFormatted.slots.$each[idxSlot].opening.required
                    "
                  >
                    {{ $t('errors.required') }}
                  </template>
                </ui-dropdown>
                <ui-dropdown
                  class="modal-exceptional-hours__content__grid__wrapper__slots__line__input"
                  :id="`dropdown-closing-${idxSlot}`"
                  :key="`dropdown-closing-${idxSlot}`"
                  v-model="slot.closing"
                  :options="hoursOptions"
                  :show-caret="false"
                  :noAbsolute="true"
                  track-by="id"
                  label="name"
                  :placeholder="$t('common.label.closing')"
                  :error="$v.hoursFormatted.slots.$each[idxSlot].closing.$error"
                  :no-results-label="$t('modal.exceptionalHours.dropdown.noResult')"
                >
                  <template
                    v-slot:helper
                    v-if="
                      $v.hoursFormatted.slots.$each[idxSlot].closing.$error &&
                      !$v.hoursFormatted.slots.$each[idxSlot].closing.required
                    "
                  >
                    {{ $t('errors.required') }}
                  </template>
                </ui-dropdown>
                <ui-button
                  class="modal-exceptional-hours__content__grid__wrapper__slots__line__cta"
                  :label="$t('modal.exceptionalHours.button.addSlot')"
                  button="secondary"
                  icon="add_hours"
                  :iconOnly="true"
                  v-if="idxSlot === 0"
                  :disabled="
                    !hoursFormatted.slots[hoursFormatted.slots.length - 1].opening ||
                    !hoursFormatted.slots[hoursFormatted.slots.length - 1].closing ||
                    hoursFormatted.slots.length === 2
                  "
                  v-tooltip="{
                    placement: 'top',
                    trigger: 'hover',
                    content: $t('modal.exceptionalHours.button.addSlot'),
                    offset: 3,
                  }"
                  @click="addSlot()"
                />
                <ui-button
                  class="modal-exceptional-hours__content__grid__wrapper__slots__line__cta"
                  :label="$t('modal.exceptionalHours.button.removeSlot')"
                  button="secondary"
                  icon="close"
                  :iconOnly="true"
                  v-if="idxSlot > 0"
                  v-tooltip="{
                    placement: 'top',
                    trigger: 'hover',
                    content: $t('modal.exceptionalHours.button.removeSlot'),
                    offset: 3,
                  }"
                  @click="deleteSlot(idxSlot)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import UiInput from '@/components/UI/Input.vue'
import UiSwitch from '@/components/UI/Switch.vue'
import UiDatePicker from '@/components/UI/DatePicker.vue'
import UiCheckbox from '@/components/UI/Checkbox.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiButton from '@/components/UI/Button.vue'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { timeArray } from '@/utils/hours.util'
import { formatedDate } from '@/utils/date.util'
import { convert24hto12h } from '@/utils/hours.util'

export default {
  name: 'ModalExceptionalHours',
  components: {
    UiInput,
    UiSwitch,
    UiDatePicker,
    UiCheckbox,
    UiDropdown,
    UiButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        type: '',
      }),
    },
    currentClient: {
      type: Object,
      required: false,
      default: () => {},
    },
    hoursFormat: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: null,
      hoursFormatted: {
        label: '',
        open: false,
        isRange: false,
        date: '',
        slots: [],
      },
      canBeDeleted: false,
      isFromPHW: false,
    }
  },
  created() {
    this.isFromPHW = !!this.data?.fromPHW
  },
  mounted() {
    this.modelData = Object.assign({}, this.data)
    if (this.modelData && this.modelData.id) {
      this.canBeDeleted = !this.modelData.fromPHW
      this.modelData.fromPHW ? this.modelDataFormat() : this.generateDays()
    }
  },
  computed: {
    hoursOptions() {
      const isAmpm = this.clientHasAmpmFormat ? true : false
      return timeArray(5, 0, isAmpm)
    },
    clientHasAmpmFormat() {
      return this.hoursFormat && this.currentClient?.ampmFormat === '1'
    },
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.convertData()
        if (this.modelData.id && !this.isFromPHW) {
          this.$emit('save', {
            objKey: this.modelData,
            status: 'update',
          })
        } else {
          this.$emit('save', {
            objKey: this.modelData,
            status: 'create',
          })
        }
        this.$v.$reset()
      }
    },
    remove() {
      this.$emit('save', {
        objKey: this.modelData,
        status: 'delete',
      })
    },
    closed() {
      this.$emit('closed')
    },
    addSlot() {
      this.hoursFormatted.slots.push({
        opening: '',
        closing: '',
      })
    },
    deleteSlot(index) {
      this.hoursFormatted.slots.splice(index, 1)
    },
    cloneSlot(slot) {
      return Object.assign(
        {},
        {
          opening: slot.opening,
          closing: slot.closing,
        }
      )
    },
    toggleSlots() {
      this.hoursFormatted = {
        ...this.hoursFormatted,
        slots: this.hoursFormatted.open
          ? [
              {
                opening: '',
                closing: '',
              },
            ]
          : [],
      }
    },
    modelDataFormat() {
      this.modelData = {
        dateFrom: this.modelData.dateFrom,
        id: this.modelData.id,
      }
      this.generateDays()
    },
    generateDays() {
      // Generate date
      let date = null
      const dateFrom = this.modelData.dateFrom
      const dateTo = this.modelData.dateTo
      if (dateFrom && !dateTo) {
        date = new Date(dateFrom)
      } else if (dateFrom && dateTo) {
        date = {
          start: new Date(dateFrom),
          end: new Date(dateTo),
        }
      }

      // Generate time slots
      const slots = []
      const hours1 = this.modelData.hours1
      const hours2 = this.modelData.hours2
      if (hours1) {
        slots.push({
          opening: { name: this.convertTime(hours1.split('-')[0]), id: hours1.split('-')[0] },
          closing: { name: this.convertTime(hours1.split('-')[1]), id: hours1.split('-')[1] },
        })
      }
      if (hours2) {
        slots.push({
          opening: { name: this.convertTime(hours2.split('-')[0]), id: hours2.split('-')[0] },
          closing: { name: this.convertTime(hours2.split('-')[1]), id: hours2.split('-')[1] },
        })
      }

      this.hoursFormatted = {
        label: this.modelData.label,
        open: !!this.modelData.openStatus,
        isRange: typeof this.modelData.dateFrom === 'string' && typeof this.modelData.dateTo === 'string',
        date,
        slots,
      }
    },
    convertData() {
      const date = this.hoursFormatted.date
      const hours = this.hoursFormatted.slots
      this.modelData = {
        ...this.modelData,
        label: this.hoursFormatted.label,
        openStatus: this.hoursFormatted.open,
        dateFrom:
          typeof date === 'object' && !date.start && !date.end
            ? formatedDate(date, 'YYYY-MM-DD')
            : formatedDate(date.start, 'YYYY-MM-DD'),
        dateTo: date.start && date.end ? formatedDate(date.end, 'YYYY-MM-DD') : '',
        hours1: hours.length >= 1 ? `${hours[0].opening.id}-${hours[0].closing.id}` : '',
        hours2: hours.length === 2 ? `${hours[1].opening.id}-${hours[1].closing.id}` : '',
      }
    },
    convertTime(time) {
      const amPm = !!(this.currentClient.ampmFormat * 1)
      if (amPm) {
        return convert24hto12h(time)
      }
      return time
    },
  },
  validations() {
    return {
      hoursFormatted: {
        label: {
          required: requiredIf(() => {
            return this.parameters.type !== 'gmb'
          }),
        },
        date: {
          required,
        },
        slots: {
          $each: {
            opening: {
              required,
            },
            closing: {
              required,
            },
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-exceptional-hours {
  &__content {
    margin: auto 0;
    min-height: 100px;

    &__description {
      margin-bottom: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-bottom: $gutter-tablet;
      }
    }

    &__grid {
      margin: (-$gutter-mobile) (-$gutter-mobile / 2);

      @media (min-width: $screen-sm) {
        margin: (-$gutter-mobile) (-$gutter-tablet / 2);
      }

      &__wrapper {
        position: relative;
        padding: $gutter-mobile $gutter-mobile / 2;

        @media (min-width: $screen-sm) {
          padding: $gutter-mobile $gutter-tablet / 2;
        }

        @media (min-width: $screen-md) {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;
        }

        &__section {
          display: flex;
          align-items: flex-start;
          margin-bottom: $gutter-mobile;

          @media (min-width: $screen-sm) {
            margin-bottom: $gutter-tablet;
          }

          @media (min-width: $screen-md) {
            width: 100%;
          }

          &__input {
            flex: 1;
          }

          &__range {
            margin-left: $gutter-mobile;
            padding-top: 25px;

            @media (min-width: $screen-sm) {
              margin-left: $gutter-tablet;
            }

            @media (min-width: $screen-md) {
              display: none;
            }
          }
        }

        &__block {
          display: flex;
          align-items: flex-start;

          @media (min-width: $screen-md) {
            width: 50%;
          }

          &__range {
            display: none;

            @media (min-width: $screen-md) {
              display: inline-flex;
              margin-right: $gutter-tablet;
              padding-top: 10px;
            }
          }

          &__date {
            flex: 1;
          }

          &__status {
            margin-left: $gutter-mobile;
            padding-top: 13px;

            @media (min-width: $screen-sm) {
              justify-self: center;
              margin-left: $gutter-tablet;
            }
          }
        }

        &__slots {
          @media (min-width: $screen-md) {
            margin-left: $gutter-tablet;
            width: calc(50% - #{$gutter-tablet});
          }

          &__line {
            display: flex;
            align-items: flex-start;
            margin-top: $gutter-mobile / 2;

            @media (min-width: $screen-sm) {
              margin-top: $gutter-tablet / 2;
            }

            &:first-child {
              @media (min-width: $screen-md) {
                margin-top: 0;
              }
            }

            &__input {
              margin-right: $gutter-mobile / 2;
              width: calc(100% / 2 - (#{$button-min-height} / 2 + #{$gutter-mobile} / 2));

              @media (min-width: $screen-sm) {
                margin-right: $gutter-tablet / 2;
                width: calc(100% / 2 - (#{$button-min-height} / 2 + #{$gutter-tablet} / 2));
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.modal-exceptional-hours {
  &__popover {
    max-width: 220px;
    overflow: hidden;

    &__cta {
      border-radius: 0;
      width: 100%;
      font-size: $font-size-default;
    }
  }
}
</style>
