<template>
  <swiper ref="slider" :options="swiperOptions" class="ui-slider" @ready="checkPagination">
    <swiper-slide class="ui-slider__slide" v-for="(slide, i) in slides" :key="`slide-${i}`">
      <slot name="slide" :slide="slide"></slot>
    </swiper-slide>
    <div class="swiper-pagination ui-slider__pagination" slot="pagination" v-show="hasPagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'UiSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  directives: {
    swiper: directive,
  },
  mounted() {
    this.swiper.on('paginationUpdate', this.checkPagination)
    this.swiper.on('init', this.$emit('initialized'))
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        loop: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      hasPagination: false,
    }
  },
  computed: {
    swiper() {
      return this.$refs.slider.$swiper
    },
  },
  methods: {
    checkPagination() {
      this.hasPagination = this.swiper.pagination.bullets.length > 1
      this.swiper.allowTouchMove = this.hasPagination
    },
  },
}
</script>

<style lang="scss">
.ui-slider {
  width: 100%;

  .swiper-wrapper {
    height: initial;
  }

  .swiper-pagination {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &-bullet {
      opacity: 0.5;
      outline: 0;
      background-color: $swiper-pagination-color-bg;
      width: $swiper-pagination-width;
      height: $swiper-pagination-height;

      &-active {
        opacity: 1;
        background-color: $swiper-pagination-color-bg-active;
        width: $swiper-pagination-width;
        height: $swiper-pagination-height;
      }
    }
  }

  &.swiper-container-horizontal > .swiper-pagination-bullets {
    position: relative;
    bottom: initial;
    margin-top: 6px;

    .swiper-pagination-bullet {
      margin: 0 5px;
    }
  }
}
</style>
