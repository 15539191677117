export const externalModules = {
  facebook: 'facebookId',
  flowboxKey: 'flowboxKey',
  embedSocialReviews: 'widgetEmbedSocialReviewsId',
  instagram: 'instagramId',
  synbird: 'synbirdId',
  youtube: 'youtubeId',
}

export const leadGenerationModes = ['contact_form', 'lead_generation']
