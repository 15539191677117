<template>
  <screen-grid class="location-lpe-gmb">
    <screen-card
      :title="$t('locations.id.gmb.section.extraData.gmb.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('locations.id.gmb.section.extraData.gmb.label')"
          icon="article"
          :has-data="gmbBindLocations.length > 0"
          ratio="1-1"
          :is-loading="isLoading"
          :is-editable="true"
          @click="
            editSimpleField(
              'ids',
              'text',
              'list',
              false,
              null,
              locationsSearch,
              {
                label: 'location_name',
                customLabel: 'location_name store_code',
                trackBy: 'place_id',
              },
              false
            )
          "
        >
          <template v-slot:data>
            <div class="location-lpe-gmb__locations">
              <span class="location-lpe-gmb__locations__item" v-for="(location, idx) in gmbBindLocations" :key="idx">
                {{ location.location_name }}
              </span>
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locations.id.gmb.section.extraData.gpbId.label')"
          icon="article"
          :has-data="true"
          :data="currentLocation.gpbId"
          :is-editable="false"
          ratio="1-2"
          :is-loading="isLoading"
          :is-required="false"
        >
          <template v-slot:data>
            <span v-if="currentLocation.gpbId">{{ currentLocation.gpbId }}</span>
            <span v-else>{{ $t('common.button.no') }}</span>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locations.id.gmb.section.extraData.placeId.label')"
          icon="attribut"
          :has-data="!!currentLocation.placeId"
          :data="currentLocation.placeId"
          :is-editable="false"
          ratio="1-2"
          :is-loading="isLoading"
          :is-required="false"
        >
          <template v-slot:data>
            <router-link
              target="_blank"
              :to="`//search.google.com/local/writereview?placeid=${currentLocation.placeId}`"
              >{{ currentLocation.placeId }}</router-link
            >
          </template>
        </screen-block>
        <screen-block
          :title="$t('locations.id.gmb.section.extraData.gmbSheet.label')"
          icon="attribut"
          :has-data="!!currentLocation.account"
          :data="currentLocation.account"
          :is-editable="false"
          ratio="1-2"
          :is-loading="isLoading"
          :is-required="false"
        >
          <template v-slot:data>
            <router-link target="_blank" :to="{ name: 'LocationGmb', params: { name: '' } }">
              {{ currentLocation.account }}
            </router-link>
          </template>
        </screen-block>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'

export default {
  name: 'LocationGmb',
  components: {
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    locationsSearch: {
      type: Array,
      required: true,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    gmbBindLocations: {
      type: Array,
      required: true,
    },
  },
  methods: {
    editSimpleField(objKey, type, mode, required = false, number = null, options = [], dropdown = {}, taggable = true) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
          dropdown,
          taggable,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.location-lpe-gmb {
  &__status {
    &__icon {
      margin-right: $gutter-mobile;
      border: 1px solid;
      border-radius: 50%;
      padding: 5px;
      width: 36px;
      height: 36px;

      .location-lpe-gmb__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-lpe-gmb__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-lpe-gmb__status--OPEN_FOR_BUSINESS_UNSPECIFIED & {
        color: map-get($generic-color-variants, 'inactive');
      }

      .location-lpe-gmb__status--OPEN & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-lpe-gmb__status--CLOSED_PERMANENTLY & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-lpe-gmb__status--CLOSED_TEMPORARILY & {
        color: map-get($generic-color-variants, 'warning');
      }
    }

    &__state {
      margin-top: 2px;

      .location-lpe-gmb__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-lpe-gmb__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-lpe-gmb__status--OPEN_FOR_BUSINESS_UNSPECIFIED & {
        color: map-get($generic-color-variants, 'inactive');
      }

      .location-lpe-gmb__status--OPEN & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-lpe-gmb__status--CLOSED_PERMANENTLY & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-lpe-gmb__status--CLOSED_TEMPORARILY & {
        color: map-get($generic-color-variants, 'warning');
      }
    }

    &__button {
      margin-left: $gutter-mobile;
    }

    &__popover {
      min-width: 280px;

      @media (min-width: $screen-sm) {
        min-width: 340px;
      }

      &__choice {
        display: flex;
        align-items: center;
        padding: 0 $gutter-mobile;
        height: 48px;

        @media (min-width: $screen-sm) {
          padding: 0 $gutter-tablet;
        }

        &:hover {
          background-color: var(--bg-color-hover);
          cursor: pointer;
        }

        &:first-child {
          border-top-left-radius: $radius-default;
          border-top-right-radius: $radius-default;
        }

        &:last-child {
          border-bottom-left-radius: $radius-default;
          border-bottom-right-radius: $radius-default;
        }

        &__icon {
          margin-right: $gutter-mobile;
        }
      }
    }
  }

  &__locations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;

    &__item {
      border-radius: 50px;
      background-color: map-get($generic-color-variants, 'data');
      padding: 2px 8px;
      color: $white;
      font-size: $font-size-sm;
    }
  }
}
</style>
