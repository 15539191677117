var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"title":_vm.title,"name":"modal-hours","has-apply":true,"has-delete":_vm.canBeDeleted,"is-updating":_vm.isUpdating,"width":900,"delete-label":_vm.$t('modal.hours.delete.label'),"delete-tooltip":_vm.$t('modal.hours.delete.tooltip')},on:{"save":_vm.save,"remove":_vm.remove,"closed":_vm.closed},scopedSlots:_vm._u([{key:"container",fn:function(){return [(_vm.parameters.type !== 'gmb')?_c('ui-input',{staticClass:"modal-hours__input",attrs:{"label":_vm.$t('modal.hours.label.name'),"id":"modal-hour-name"},model:{value:(_vm.modelData.label),callback:function ($$v) {_vm.$set(_vm.modelData, "label", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelData.label"}}):_vm._e(),(_vm.parameters.type !== 'gmb')?_c('div',{staticClass:"modal-hours__visibility"},[_c('div',{staticClass:"modal-hours__visibility__block"},[_c('ui-date-picker',{staticClass:"modal-hours__visibility__block__input",attrs:{"locale":_vm.$i18n.locale,"max-date":_vm.dateTo,"id":"modal-media-from","label":_vm.$t('modal.hours.label.dateFrom')},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),(_vm.dateFrom)?_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            placement: 'top',
            trigger: 'hover',
            content: _vm.$t('modal.hours.button.removeDateFrom'),
            offset: 3,
          }),expression:"{\n            placement: 'top',\n            trigger: 'hover',\n            content: $t('modal.hours.button.removeDateFrom'),\n            offset: 3,\n          }"}],staticClass:"modal-hours__visibility__block__remove",attrs:{"button":"secondary","icon":"close","icon-only":true,"label":_vm.$t('modal.hours.button.removeDateFrom')},on:{"click":function($event){return _vm.clearDate('dateFrom')}}}):_vm._e()],1),_c('div',{staticClass:"modal-hours__visibility__block"},[_c('ui-date-picker',{staticClass:"modal-hours__visibility__block__input",attrs:{"locale":_vm.$i18n.locale,"min-date":_vm.dateFrom,"id":"modal-media-to","label":_vm.$t('modal.hours.label.dateTo')},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}),(_vm.dateTo)?_c('ui-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            container: '.modal__wrapper__box',
            placement: 'top',
            trigger: 'hover',
            content: _vm.$t('modal.hours.button.removeDateTo'),
            offset: 3,
          }),expression:"{\n            container: '.modal__wrapper__box',\n            placement: 'top',\n            trigger: 'hover',\n            content: $t('modal.hours.button.removeDateTo'),\n            offset: 3,\n          }"}],staticClass:"modal-hours__visibility__block__remove",attrs:{"button":"secondary","icon":"close","icon-only":true,"label":_vm.$t('modal.hours.button.removeDateTo')},on:{"click":function($event){return _vm.clearDate('dateTo')}}}):_vm._e()],1)]):_vm._e(),_c('v-tabs',{staticClass:"modal-hours__tabs",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab},[_vm._v(" "+_vm._s(_vm.$t(("modal.hours.tabs." + tab)))+" ")])}),1),_c('v-tabs-items',{staticClass:"modal-hours__items",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:("item " + tab),staticClass:"modal-hours__items__day"},[_c('div',{staticClass:"modal-hours__tabs__items__day__grid"},_vm._l((_vm.daysArray),function(day,idx){return _c('modal-extend-hours-day',{key:("day-" + (day.key) + "-" + idx),attrs:{"hours-format":_vm.hoursFormat,"current-client":_vm.currentClient,"day-key":day.key,"day-idx":idx,"day":day,"days-array":_vm.daysArray,"duplicating-day":_vm.duplicatingDay,"duplicate-array":_vm.duplicateArray,"validations":_vm.$v,"his-custom-hours":tab === 'appointment'},on:{"toggleSlots":_vm.toggleSlots,"stopDuplicating":_vm.stopDuplicating,"duplicate":_vm.duplicate,"endDuplicating":_vm.endDuplicating,"addSlot":_vm.addSlot,"addCustomSlot":_vm.addCustomSlot,"deleteSlot":_vm.deleteSlot,"deleteCustomSlot":_vm.deleteCustomSlot,"setDuplicateArray":_vm.setDuplicateArray,"updateSlotHours":_vm.updateSlotHours}})}),1)])}),1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }