import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import i18n from '@/i18n'
dayjs.extend(relativeTime)

/**
 * Returns the string of relative time from now, in the requested locale
 * @param {string} date date to parse
 * @param {string} locale locale to use for display
 * @returns {string} Parsed and formatted time
 */
export const timeFromNow = (date, suffix = false, locale = i18n.locale) => {
  require(`dayjs/locale/${locale}.js`)

  return dayjs(date)
    .locale(locale)
    .fromNow(suffix === true ? false : true)
}

export const timeArray = (interval = 5, startTime = 0, amPm = false) => {
  const times = []
  const recurrence = 60 / interval

  for (let hour = startTime; hour < 24; hour++) {
    times.push({
      name: dayjs(`2020-01-01 ${hour}:00`).format(amPm ? 'h:mm A' : 'H:mm'),
      id: dayjs(`2020-01-01 ${hour}:00`).format('H:mm'),
    })
    for (let i = 1; i < recurrence; i++) {
      times.push({
        name: dayjs(`2020-01-01 ${hour}:00`)
          .add(interval * i, 'minute')
          .format(amPm ? 'h:mm A' : 'H:mm'),
        id: dayjs(`2020-01-01 ${hour}:00`)
          .add(interval * i, 'minute')
          .format('H:mm'),
      })
    }
  }
  return times
}

export const convert24hto12h = time => {
  let [hours, minutes] = time.split(':')
  const amOrPm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12 || 12

  return `${hours}:${minutes} ${amOrPm}`
}
