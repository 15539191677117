<template>
  <div class="ui-clipper">
    <div
      class="ui-clipper__container"
      v-if="displayPreview && (picture || image.base64)"
      @drop.prevent="cropImg($event.dataTransfer.files[0])"
      @dragover.prevent
    >
      <img :src="picture || image.base64" class="ui-clipper__container__img" alt="preview" />
      <div class="ui-clipper__container__actions" v-if="type !== 'locationPictures'">
        <ui-button
          class="ui-clipper__container__actions__edit"
          button="cta"
          variant="data"
          icon="add_photo"
          :icon-only="true"
          :label="$t('common.button.updateImg')"
          v-tooltip="{
            placement: 'top',
            trigger: 'hover',
            content: $t('common.button.updateImg'),
            offset: 3,
          }"
          @click.stop="$refs.mediaImg.click()"
        />
        <ui-button
          class="ui-clipper__container__actions__delete"
          button="cta"
          variant="error"
          icon="delete"
          :icon-only="true"
          :label="$t('common.button.deleteImg')"
          v-tooltip="{
            placement: 'top',
            trigger: 'hover',
            content: $t('common.button.deleteImg'),
            offset: 3,
          }"
          @click.stop="deleteImg"
        />
      </div>
    </div>
    <div
      class="ui-clipper__placeholder"
      v-else
      @click.stop="$refs.mediaImg.click()"
      @drop.prevent="cropImg($event.dataTransfer.files[0])"
      @dragover.prevent
    >
      <ui-button
        class="ui-clipper__placeholder__add"
        button="primary"
        variant="data"
        :icon="buttonIcon"
        :icon-only="true"
        :label="$t('common.button.addImg')"
        v-tooltip="{
          placement: 'top',
          trigger: 'hover',
          content: $t('common.button.addImg'),
          offset: 3,
        }"
        @click.stop="$refs.mediaImg.click()"
      />
      <div class="ui-clipper__placeholder__caption">
        {{ localizedPlaceholder || $t('modal.clipper.noPicture') }}
      </div>
    </div>
    <input
      type="file"
      :accept="acceptFiles"
      ref="mediaImg"
      class="ui-clipper__file"
      @change="cropImg($event.target.files[0])"
    />
    <modal-clipper :image="image.preview" @clip-picture="clipPicture" />
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import ModalClipper from '@/components/Modal/Clipper.vue'

export default {
  name: 'UiClipper',
  components: {
    UiButton,
    ModalClipper,
  },
  props: {
    picture: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    buttonIcon: {
      type: String,
      required: false,
      default: 'add_photo',
    },
    localizedPlaceholder: {
      type: String,
      required: false,
      default: null,
    },
    returnAllFileInfos: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayPreview: {
      type: Boolean,
      required: false,
      default: true,
    },
    acceptFiles: {
      type: String,
      required: false,
      default: 'image/*',
    },
  },
  data() {
    return {
      image: {
        preview: null,
        file: null,
        type: null,
        base64: null,
      },
    }
  },
  methods: {
    deepCopyMediaInfos(image) {
      const { file, ...rest } = image
      const fileCopy = new File([file], file.name)
      return { ...rest, file: fileCopy }
    },
    cropImg(file) {
      this.image.file = file
      const imageReader = new FileReader()
      imageReader.addEventListener(
        'load',
        () => {
          this.image.preview = imageReader.result
          if (this.image.type === 'image/svg+xml' || this.image.type.includes('video/')) {
            this.image.base64 = this.image.preview
            this.$emit('clip', this.returnAllFileInfos ? this.deepCopyMediaInfos(this.image) : this.image.preview)
          } else {
            this.$modal.show('modal-clipper')
          }
        },
        false
      )
      if (this.image.file) {
        this.image.type = this.image.file.type
        imageReader.readAsDataURL(this.image.file)
      }
      this.$refs.mediaImg.value = ''
    },
    clipPicture(canvas) {
      this.image.base64 = canvas.toDataURL(this.image.type, 0.9)
      this.$modal.hide('modal-clipper')
      this.$emit('clip', this.returnAllFileInfos ? this.deepCopyMediaInfos(this.image) : this.image.base64)
    },
    deleteImg() {
      this.image.base64 = null
      this.$emit('delete')
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-clipper {
  &__container {
    display: flex;
    justify-content: center;
    border-radius: $radius-default;
    background-color: var(--bg-color-hue);
    overflow: hidden;

    &__img {
      display: flex;
      border-radius: $radius-default;
      max-width: 100%;
    }

    &__actions {
      display: flex;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      justify-content: center;
      padding: 6px;

      &__delete {
        margin-left: $gutter-mobile;

        @media (min-width: $screen-sm) {
          margin-left: $gutter-tablet;
        }
      }
    }
  }

  &__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $radius-input;
    background-color: var(--bg-color-hue);
    cursor: pointer;
    height: 150px;

    @media (min-width: $screen-sm) {
      height: 200px;
    }

    &__add {
      margin-bottom: 4px;
    }

    &__caption {
      color: var(--input-placeholder-color);
    }
  }

  &__file {
    display: none;
  }
}
</style>
